import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  function handleLanguageChange(event) {
    const languageCode = event.target.value;
    i18n.changeLanguage(languageCode);
  }
  return (
    <div
      style={{
        color: "white",
      }}
    >
      <label htmlFor="language-select">Language : </label>
      <select id="language-select" onChange={handleLanguageChange}>
        {/* <option value="">Language</option> */}
        <option value="en">English</option>
        <option value="zh">中文</option>
      </select>
    </div>
  );
};

export default LanguageSwitcher;
