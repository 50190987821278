const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pagesToShow = [];
    let startPage, endPage;
  
    if (totalPages <= 9) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 5) {
        startPage = 1;
        endPage = 9;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 8;
        endPage = totalPages;
      } else {
        startPage = currentPage - 4;
        endPage = currentPage + 4;
      }
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pagesToShow.push(i);
    }
  
    return (
      <div className="pagination">
        <button
          className="prev"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {startPage > 1 && <span className="dots">...</span>}
        {pagesToShow.map((page) => (
          <button
            key={page}
            className={`page${page === currentPage ? ' active' : ''}`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages && <span className="dots">...</span>}
        <button
          className="next"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };
  
  export default Pagination