import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ServiceProviderBtnArea = () => {
  const { t } = useTranslation();

  return (
    <div className="about-area pd-bottom-80">
      <div className="container">
        <div
          className="row"
          // style={{
          //     display: 'flex',
          //     justifyContent: 'evenly',
          //     alignItems: 'center'
          // }}
        >
          <div className="col-md-6">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link
                className="btn btn-border-base"
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="1500"
                to="/service-provider-signup"
              >
                {t("matchSerPro.serProRegistration")}
              </Link>
            </div>
          </div>

          <div className="col-md-6">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link
                className="btn btn-border-base"
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="1500"
                to="/service-provider-matching"
              >
                {t("matchSerPro.matchYourSerPro")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceProviderBtnArea;
