import React from "react";
import {
  FaAngleLeft,
  FaAngleRight,
  FaCalendarAlt,
  FaChevronRight,
  FaFacebookF,
  FaRegFolderOpen,
  FaInstagram,
  FaPlus,
  FaTwitter,
  FaRegUser,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const NewsSections = () => {
  return (
    <>
      {/* =============== Blog Group start ===============*/}
      <div className="blog-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-blog-inner">
                <div className="thumb">
                  <img
                    src="assets/img/news/news-ai-powered-robot-chef-startup.png"
                    alt="img"
                  />
                  <span className="date">10 APR</span>
                </div>
                <div className="details">
                  <ul className="blog-meta">
                    <li>
                      <FaRegUser /> By Admin
                    </li>
                    <li>
                      <FaRegFolderOpen /> Category
                    </li>
                  </ul>
                  <h2 className="title">
                    <Link to="/forum-details">
                      AI-Powered Robot Chef Startup Raises $10 Million in
                      Funding
                    </Link>
                  </h2>

                  <p>
                    Cookify is a startup that claims to have developed an
                    AI-powered robot chef that can cook meals quickly and
                    efficiently. The startup has announced a funding round of
                    $10 million, which it claims was led by top venture
                    capitalists. The news is entirely fabricated, and Cookify
                    does not exist.
                  </p>
                  <Link
                    className="btn btn-border-base mt-3"
                    to="/service-details"
                  >
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>

              <div className="single-blog-inner">
                <div className="thumb">
                  <img
                    src="assets/img/news/news-healthtech-startup-launches-wearable-device.png"
                    alt="img"
                  />
                  <span className="date">7 APR</span>
                </div>
                <div className="details">
                  <ul className="blog-meta">
                    <li>
                      <FaRegUser /> By Admin
                    </li>
                    <li>
                      <FaRegFolderOpen /> Category
                    </li>
                  </ul>
                  <h2 className="title">
                    <Link to="/forum-details">
                      Healthtech Startup Launches Wearable Device to Detect
                      COVID-19 Symptoms Early
                    </Link>
                  </h2>
                  <p>
                    SymptomTech, a healthtech startup, has launched a new
                    wearable device that uses AI and machine learning to detect
                    COVID-19 symptoms early, helping people get tested and
                    treated quickly.
                  </p>
                  <Link
                    className="btn btn-border-base mt-3"
                    to="/service-details"
                  >
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>

              <div className="single-blog-inner">
                <div className="thumb">
                  <img
                    src="assets/img/news/social-media-startup-creates-platform.png"
                    alt="img"
                  />
                  <span className="date">3 APR</span>
                </div>
                <div className="details">
                  <ul className="blog-meta">
                    <li>
                      <FaRegUser /> By Admin
                    </li>
                    <li>
                      <FaRegFolderOpen /> Category
                    </li>
                  </ul>
                  <h2 className="title">
                    <Link to="/forum-details">
                      Social Media Startup Creates Platform to Connect Dog
                      Owners with Local Dog Parks
                    </Link>
                  </h2>
                  <p>
                    BarkBuddy, a social media startup, has launched a new
                    platform that connects dog owners with local dog parks,
                    helping them find safe and fun places to take their furry
                    friends.
                  </p>
                  <Link
                    className="btn btn-border-base mt-3"
                    to="/service-details"
                  >
                    Touch More <FaPlus />
                  </Link>
                </div>
              </div>
              <div className="pagination">
                <a className="prev page-numbers" href="http://icare.local/">
                  <FaAngleLeft />
                </a>
                <a className="page-numbers" href="http://icare.local/">
                  1
                </a>
                <span className="page-numbers current">2</span>
                <a className="page-numbers" href="http://icare.local/page/3/">
                  3
                </a>
                <a className="page-numbers" href="http://icare.local/page/4/">
                  4
                </a>
                <a
                  className="next page-numbers"
                  href="http://icare.local/page/3/"
                >
                  <FaAngleRight />
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <div className="td-sidebar">
                <div className="widget widget-recent-post">
                  <h4 className="widget-title">Recent News</h4>
                  <ul>
                    <li>
                      <div className="media">
                        <div
                          className="media-left"
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                        >
                          <img src="assets/img/news/news-revolutionize-telemedicine.png" />
                        </div>
                        <div className="media-body align-self-center">
                          <h6 className="title">
                            <Link to="/forum-details">
                              GloboHealth Raises $20M to Revolutionize
                              Telemedicine
                            </Link>
                          </h6>
                          <div className="post-info">
                            <FaCalendarAlt />
                            <span>2 April</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media">
                        <div
                          className="media-left"
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                        >
                          <img src="assets/img/news/news-urban-farming-solution-for-sustainable-living.png" />
                        </div>
                        <div className="media-body align-self-center">
                          <h6 className="title">
                            <Link to="/forum-details">
                              EcoGardens Launches Urban Farming Solution for
                              Sustainable Living
                            </Link>
                          </h6>
                          <div className="post-info">
                            <FaCalendarAlt />
                            <span>1 April</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media">
                        <div
                          className="media-left"
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                        >
                          <img src="assets/img/news/news-new-payment-app.png" />
                        </div>
                        <div className="media-body align-self-center">
                          <h6 className="title">
                            <Link to="/forum-details">
                              XpandPay Launches New Payment App for Small
                              Businesses
                            </Link>
                          </h6>
                          <div className="post-info">
                            <FaCalendarAlt />
                            <span>29 March</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="widget widget_search">
                  <form className="search-form">
                    <div className="form-group">
                      <input type="text" placeholder="News Search" />
                    </div>

                    <button className="submit-btn" type="submit">
                      <FaChevronRight />
                    </button>
                  </form>
                </div>

                <div className="widget widget_catagory">
                  <h4 className="widget-title">Catagory</h4>
                  <ul className="catagory-items">
                    <li>
                      <Link to="/forum">
                        Business <span>3</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/forum">
                        Healthcare <span>7</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/forum">
                        Technology <span>2</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/forum">
                        Finance <span>3</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/forum">
                        Service <span>5</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="widget widget_tag_cloud mb-0">
                  <h4 className="widget-title">Tags</h4>
                  <div className="tagcloud">
                    <a href="#">Information</a>
                    <a href="#">Learn</a>
                    <a href="#">ICT</a>
                    <a href="#">Business</a>
                    <a href="#">Portfolio</a>
                    <a href="#">Project</a>
                    <a href="#">Personal</a>
                    <a href="#">Server</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== Blog Group End ===============*/}
    </>
  );
};

export default NewsSections;
