import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import AboutArea from "../components/AboutArea";
import CounterArea from "../components/CounterArea";
import FaqArea from "../components/FaqArea";
import TeamArea from "../components/TeamArea";
import ContactArea from "../components/ContactArea";
import SignUpProcess from "../components/SignUpProcess";

import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"About Us"} />

      {/* About Area */}
      <AboutArea />

      {/* FAQ Area */}
      <FaqArea />

      {/* Team Area */}
      {/* <TeamArea /> */}

      {/* Counter Area */}
      <CounterArea />

      {/* Contact Area */}
      <ContactArea />

      {/* Signup Process */}
      <SignUpProcess />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default About;
