import React from "react";
import { useTranslation } from "react-i18next";

const FaqArea = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110"
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="container">
          <div className="row pd-top-120">
            <div
              className="col-xl-5 col-lg-6 col-md-8 order-lg-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner pt-lg-3">
                <img
                  className="main-img"
                  src="assets/img/about/faq.jpeg"
                  style={{
                    width: "550px",
                    height: "490px",
                    "@media (max-width: 767px)": {
                      width: "350px",
                      height: "250px",
                    },
                  }}
                  alt="img"
                />
                {/* <img
                  className="animate-img-bottom-right top_image_bounce"
                  src="assets/img/about/5.png"
                  alt="img"
                /> */}
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-6"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h6 className="sub-title">{t('faq.subTitle')}</h6>
                <h2 className="title">
                {t('faq.title_part_1')} <span>{t('faq.title_part_2')}</span> {t('faq.title_part_3')}
                </h2>
                <p className="content">
                  These FAQs provide an overview of our platform, including what
                  it is, how it works, and the benefits of using one. We aim to
                  help potential users understand the basics of using such a
                  platform to match the right person for their startup or
                  business idea.
                </p>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is a co-founder matching platform?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      A co-founder matching platform is an online platform that
                      helps entrepreneurs find co-founders who
                      share their vision, skills, and expertise. These platforms
                      usually have a database of potential co-founders, which
                      users can search through to find the right match.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How does a co-founder matching platform work?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      To use a co-founder matching platform, you will typically
                      create a profile outlining your skills, experience, and
                      what you're looking for in a co-founder. You can then
                      search the platform's database for potential matches based
                      on criteria such as location, skills, and industry. Once
                      you find someone who looks like a good fit, you can reach
                      out to them through the platform and start a conversation.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What are the benefits of using a co-founder matching
                      platform?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Using a co-founder matching platform can be beneficial for
                      entrepreneurs and startups in several ways. Firstly, it
                      can help you find someone with the skills and experience
                      you need to bring your business idea to life.
                      Additionally, having a co-founder can help distribute the
                      workload, provide a different perspective, and offer
                      emotional support. Using a platform can also help save
                      time and resources that would otherwise be spent searching
                      for potential co-founders through personal networks or job
                      postings.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqArea;
