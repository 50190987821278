import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Breadcrumb = ({ title }) => {
  const { t } = useTranslation()

  return (
    <>
      {/* ================ Breadcrumb Start ================*/}
      <div
        className="breadcrumb-area bg-cover"
        style={{ backgroundImage: 'url("./assets/img/white-bg.jpeg")' }}
      >
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h2 className="page-title">{title}</h2>
              </div>
              <div className="col-lg-6 text-lg-end">
                <ul className="page-list">
                  <li>
                    <Link to="/">{t('breadcrumb.home')}</Link>
                  </li>
                  {'  '}/ <li>{title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================ Breadcrumb End ================*/}
    </>
  )
}

export default Breadcrumb
