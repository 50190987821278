import React from "react";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import UserSignUpArea from "../components/UserSignUpArea";

const UserSignUp = () => {
  return (
    <div>
      {/* Navigation Bar */}
      <NavBar />

      {/* User Signup Area */}
      <UserSignUpArea />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default UserSignUp;
