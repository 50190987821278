import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MatchedCofounderArea from "../components/MatchedCofounderArea";

const MatchedCofounder = () => {
  return (
    <div>
      <NavBar />

      <MatchedCofounderArea />

      <Footer />
    </div>
  );
};

export default MatchedCofounder;
