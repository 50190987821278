import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import NewsSections from "../components/NewsSections";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

const News = () => {
  return (
    <div>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"News"} />

      {/* Forum Group */}
      <NewsSections />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default News;
