import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import { useTranslation } from "react-i18next";
import userList from "../scripts/userList";

const ITEMS_PER_PAGE = 9;

const UserMatchingArea = () => {
  const { t } = useTranslation();

  const [industry, setIndustry] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [businessType, setBusinessType] = React.useState("");
  const [keywords, setKeywords] = React.useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleBusinessTypeChange = (event) => {
    setBusinessType(event.target.value);
  };

  const handleKeywordsChange = (event) => {
    setKeywords(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("search co-founer handling...");
    // Handle search submit
  };

  // ------------------------- Pagination -------------------------

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);

    const { innerHeight } = window;
    const isMobile = innerHeight > 0 && innerHeight < 768;
    const topOffset = isMobile ? 64 : 0;
    window.scrollTo(0, topOffset);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentUserList = userList.slice(startIndex, endIndex);
  const totalPages = Math.ceil(userList.length / ITEMS_PER_PAGE);

  return (
    <>
      <div className="service-area bg-gray bg-relative pd-top-90 pd-bottom-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h6 className="sub-title">CO-FOUNDERS MATCHING</h6>
                <h2 className="title">
                  {t("matchingService.optimizeTittle")}{" "}
                  <span>{t("matchingService.smartMatching")}</span>
                </h2>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "60px",
            }}
          >
            <form className="search-box" onSubmit={handleSubmit}>
              <div className="search-box__form-group">
                <label htmlFor="industry">
                  {t("matchingService.industry")}
                </label>
                <select
                  id="industry"
                  name="industry"
                  onChange={handleIndustryChange}
                >
                  <option value="">
                    {t("matchingService.industrySelection")}
                  </option>
                  <option value="technology">
                    {t("matchingService.industry_technology")}
                  </option>
                  <option value="food">
                    {t("matchingService.industry_food")}
                  </option>
                  <option value="retail">
                    {t("matchingService.industry_retail")}
                  </option>
                  <option value="education">
                    {t("matchingService.industry_education")}
                  </option>
                  <option value="service">
                    {t("matchingService.industry_service")}
                  </option>
                  <option value="cultural">
                    {t("matchingService.industry_cultural")}
                  </option>
                  <option value="environment">
                    {t("matchingService.industry_environment")}
                  </option>
                </select>
              </div>
              <div className="search-box__form-group">
                <label htmlFor="gender">{t("matchingService.gender")}</label>
                <select id="gender" name="gender" onChange={handleGenderChange}>
                  <option value="">
                    {t("matchingService.genderSelection")}
                  </option>
                  <option value="male">{t("matchingService.male")}</option>
                  <option value="female">{t("matchingService.female")}</option>
                </select>
              </div>
              <div className="search-box__form-group">
                <label htmlFor="business-type">
                  {t("matchingService.businessType")}
                </label>
                <select
                  id="business-type"
                  name="business-type"
                  onChange={handleBusinessTypeChange}
                >
                  <option value="">
                    {t("matchingService.businessTypeSelection")}
                  </option>
                  <option value="startup">
                    {t("matchingService.startup")}
                  </option>
                  <option value="corporation">
                    {t("matchingService.corporation")}
                  </option>
                  <option value="small-business">
                    {t("matchingService.smallBusiness")}
                  </option>
                </select>
              </div>
              <div className="search-box__form-group">
                <label htmlFor="keywords">
                  {t("matchingService.specificSkills")}
                </label>
                <input
                  id="keywords"
                  name="keywords"
                  type="text"
                  onChange={handleKeywordsChange}
                />
              </div>

              <Link to="/matched-cofounder">
                <button type="submit">{t("matchingService.search")}</button>
              </Link>
            </form>
          </div>

          <div className="row">
            {currentUserList.map((data) => (
              <div key={data.id} className="col-lg-4 col-md-6">
                <div className="single-service-inner-2 text-center">
                  <div className="thumb">
                    <img src={data.company_logo} alt="img" />
                  </div>
                  <div className="details">
                    <div className="icon mb-3">
                      <img
                        src={data.user_icon}
                        alt="img"
                        style={{
                          width: "150px",
                          heigh: "150px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <h5>
                      <Link to="/">{data.user_name}</Link>
                    </h5>
                    <p>{data.user_skill}</p>

                    <p
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                        marginTop: "4px",
                        marginBottom: "4px",
                      }}
                    >
                      {data.startup_industry}
                    </p>

                    <p
                      style={{
                        color: "black",
                      }}
                    >
                      {data.company_description}
                    </p>
                  </div>
                </div>
              </div>
            ))}

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserMatchingArea;
