import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import AdviserMatchArea from "../components/AdviserMatchArea";

const Advisers = () => {
  return (
    <div>
      <NavBar />

      <AdviserMatchArea />

      <Footer />
    </div>
  );
};

export default Advisers;
