let serviceList = [
  {
    title: "Accountant",
    des: "The role of an accountant is to manage and analyze financial information, provide financial advice, and ensure compliance with financial regulations.",
    img: "assets/img/service-icon/1.png",
  },
  {
    title: "Auditor",
    des: "The role of an auditor is to independently examine and verify the financial records and statements of an organization to ensure they are accurate and comply with relevant laws and regulations.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Company Secretary",
    des: "The role of a Company Secretary is to ensure that a company complies with all legal and regulatory requirements and to provide administrative and governance support to the board of directors.",
    img: "assets/img/service-icon/3.png",
  },
  {
    title: "Marketing",
    des: "The role of marketing is to to help businesses promote their products or services, increase brand awareness, and ultimately drive sales through strategic marketing initiatives and campaigns.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Legal Advisor",
    des: "The role of a legal advisor is to provide guidance and advice on legal matters to individuals, organizations, or businesses to ensure they comply with relevant laws and regulations and avoid legal risks.",
    img: "assets/img/service-icon/1.png",
  },

  {
    title: "Financial Advisor",
    des: "The role of a financial advisor is to provide expert guidance and advice to individuals or businesses regarding their financial decisions and investments.",
    img: "assets/img/service-icon/3.png",
  },
  {
    title: "Human Resource Agency",
    des: "The role of a Human Resource Agency is to manage and oversee various aspects of the employment process, including recruitment, hiring, training, and personnel management, in order to ensure that an organization's workforce is productive, engaged, and aligned with its goals.",
    img: "assets/img/service-icon/3.png",
  },
  {
    title: "Job Agency",
    des: "The role of a job agency is to help with connecting job seekers with potential employers, team members for startup companies and assists in the recruitment process.",
    img: "assets/img/service-icon/3.png",
  },

];

export default serviceList;
