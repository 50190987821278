let serviceProviderList = [
  {
    id: 1,
    type: "accountant",
    logo: "assets/img/serProMatchLogo/logo-A1.png",
    firm: "Apex Accounting CPA Limited - 英智会计",
    serviceDescription:
      "Provide a range of services related to financial reporting, tax compliance, and financial management",
    serviceArea: "Accounting, Audit, Tax services",
    rating: 4,
    estimatedMinPrice: "3000",
    estimatedMaxPrice: "75000",
  },
  {
    id: 2,
    type: "accountant",
    logo: "assets/img/serProMatchLogo/logo-O.png",
    firm: "Oceanview Accounting CPA Limited - 海景会计",
    serviceDescription:
      "Provide a range of services related to financial reporting, tax compliance, and financial management",
    serviceArea: "Accounting, Auditing, Tax services, Consulting services",
    rating: 3,
    estimatedMinPrice: "2500",
    estimatedMaxPrice: "100000",
  },
  {
    id: 3,
    type: "accountant",
    logo: "assets/img/serProMatchLogo/logo-B.png",
    firm: "BlueMoon Accounting CPA Limited - 蓝月亮会计",
    serviceDescription:
      "Provide a range of services related to financial reporting, tax compliance, and financial management",
    serviceArea: "Accounting, Auditing, Tax services, Consulting services",
    rating: 5,
    estimatedMinPrice: "2000",
    estimatedMaxPrice: "90000",
  },
  {
    id: 4,
    type: "accountant",
    logo: "assets/img/serProMatchLogo/logo-S.png",
    firm: "Summit Accounting CPA Limited - 高峰会计",
    serviceDescription:
      "Provide a range of services related to financial reporting, tax compliance, and financial management",
    serviceArea: "Accounting, Auditing, Tax services",
    rating: 3,
    estimatedMinPrice: "2000",
    estimatedMaxPrice: "60000",
  },
  {
    id: 5,
    type: "accountant",
    logo: "assets/img/serProMatchLogo/logo-K.png",
    firm: "Keystone Accounting CPA Limited - 基石会计",
    serviceDescription:
      "Provide a range of services related to financial reporting, tax compliance, and financial management",
    serviceArea:
      "Accounting, Auditing, Tax services, Consulting services, Business valuation",
    rating: 3,
    estimatedMinPrice: "2000",
    estimatedMaxPrice: "70000",
  },
  {
    id: 6,
    type: "accountant",
    logo: "assets/img/serProMatchLogo/logo-V.png",
    firm: "Vertex Accounting CPA Limited - 顶点会计",
    serviceDescription:
      "Provide a range of services related to financial reporting, tax compliance, and financial management",
    serviceArea: "Accounting, Auditing",
    rating:2,
    estimatedMinPrice: "5000",
    estimatedMaxPrice: "40000",
  },
  {
    id: 7,
    type: "legalAdvisor",
    logo: "assets/img/serProMatchLogo/logo-W1.png",
    firm: "Wong Chan Ng Law Firm",
    serviceDescription: "To provide general legal advice services for startups",
    serviceArea: "Legal Advice Service",
    rating: 3,
    estimatedMinPrice: "3000",
    estimatedMaxPrice: "100000",
  },
  {
    id: 8,
    type: "legalAdvisor",
    logo: "assets/img/serProMatchLogo/logo-C.png",
    firm: "Chen Wong Chun Law Firm",
    serviceDescription: "To provide general legal advice services for startups",
    serviceArea: "Legal Advice Service",
    rating: 3,
    estimatedMinPrice: "8000",
    estimatedMaxPrice: "90000",
  },
  {
    id: 9,
    type: "auditor",
    logo: "assets/img/serProMatchLogo/logo-W2.png",
    firm: "Wong Chun Sum CPA Limited",
    serviceDescription: "To provide audit service for startups and SMEs",
    serviceArea: "Audit & Accounting",
    rating: 3,
    estimatedMinPrice: "3000",
    estimatedMaxPrice: "50000",
  },
  {
    id: 10,
    type: "auditor",
    logo: "assets/img/serProMatchLogo/logo-L.png",
    firm: "Lee Chen Fung CPA Limited",
    serviceDescription: "To provide audit service for startups and SMEs",
    serviceArea: "Audit & Accounting",
    rating: 4,
    estimatedMinPrice: "2500",
    estimatedMaxPrice: "45000",
  },
  {
    id: 11,
    type: "comSec",
    logo: "assets/img/serProMatchLogo/logo-P.png",
    firm: "Pro-Sec Services Ltd. 精安秘書服務有限公司",
    serviceDescription:
      "To provide company secretary service for startups and SMEs",
    serviceArea: "Company Secretary",
    rating: 3,
    estimatedMinPrice: "2500",
    estimatedMaxPrice: "10000",
  },
  {
    id: 12,
    type: "comSec",
    logo: "assets/img/serProMatchLogo/logo-S1.png",
    firm: "Secure Secretary Co. 安心秘書服務有限公司",
    serviceDescription:
      "To provide company secretary service for startups and SMEs",
    serviceArea: "Company Secretary",
    rating: 2,
    estimatedMinPrice: "2500",
    estimatedMaxPrice: "10000",
  },
  {
    id: 13,
    type: "comSec",
    logo: "assets/img/serProMatchLogo/logo-E.png",
    firm: "Elite Secretarial Solutions Inc. 菁英秘書解決方案股份有限公司",
    serviceDescription:
      "To provide company secretary service for startups and SMEs",
    serviceArea: "Company Secretary",
    rating: 3,
    estimatedMinPrice: "2500",
    estimatedMaxPrice: "10000",
  },
  {
    id: 14,
    type: "comSec",
    logo: "assets/img/serProMatchLogo/logo-P1.png",
    firm: "Premier Secretariat LLC. 頂級秘書服務有限公司",
    serviceDescription:
      "To provide company secretary service for startups and SMEs",
    serviceArea: "Company Secretary",
    rating: 3,
    estimatedMinPrice: "3000",
    estimatedMaxPrice: "40000",
  },
  {
    id: 15,
    type: "comSec",
    logo: "assets/img/serProMatchLogo/logo-T.png",
    firm: "Top Notch Corporate Services Ltd. 卓越秘書服務有限公司",
    serviceDescription:
      "To provide company secretary service for startups and SMEs",
    serviceArea: "Company Secretary",
    rating: 3,
    estimatedMinPrice: "3000",
    estimatedMaxPrice: "40000",
  },
  {
    id: 16,
    type: "marketing",
    logo: "assets/img/serProMatchLogo/logo-B1.png",
    firm: "BrightSpark Marketing - 明點行銷",
    serviceDescription:
      "To provide strategic marketing service for startups and SMEs",
    serviceArea:
      "Market research, Branding, Advertising, Public relations, Email marketing",
    rating: 4,
    estimatedMinPrice: "4000",
    estimatedMaxPrice: "80000",
  },
  {
    id: 17,
    type: "marketing",
    logo: "assets/img/serProMatchLogo/logo-M.png",
    firm: "Momentum Marketing - 動能行銷",
    serviceDescription:
      "To provide market research, branding, advertising service for startups and SMEs",
    serviceArea:
      "Market research, Branding, Advertising, Public relations, Email marketing",
    rating: 5,
    estimatedMinPrice: "7000",
    estimatedMaxPrice: "65000",
  },
  {
    id: 18,
    type: "marketing",
    logo: "assets/img/serProMatchLogo/logo-S1.png",
    firm: "Sparkle Branding - 閃耀品牌",
    serviceDescription:
      "To provide strategic marketing service for startups and SMEs",
    serviceArea: "Market research, Branding, Email marketing",
    rating: 3,
    estimatedMinPrice: "4000",
    estimatedMaxPrice: "36000",
  },
  {
    id: 19,
    type: "marketing",
    logo: "assets/img/serProMatchLogo/logo-S2.png",
    firm: "Shine Marketing - 閃耀行銷",
    serviceDescription:
      "To provide strategic marketing service for startups and SMEs",
    serviceArea:
      "Market research, Branding, Advertising, Public relations, Email marketing",
    rating: 4,
    estimatedMinPrice: "9000",
    estimatedMaxPrice: "100000",
  },
  {
    id: 20,
    type: "finAdvisor",
    logo: "assets/img/serProMatchLogo/logo-A.png",
    firm: "Alliance Finance Limited",
    serviceDescription:
      "To provide financial advisory service for SMEs and startup companies",
    serviceArea: "Financial Services",
    rating: 3,
    estimatedMinPrice: "9000",
    estimatedMaxPrice: "100000",
  },
  {
    id: 21,
    type: "hrAdvisor",
    logo: "assets/img/serProMatchLogo/logo-M.png",
    firm: "M & K HR Consulting Limited",
    serviceDescription:
      "To provide HR advisory service for SMEs and startup companies",
    serviceArea: "HR Services",
    rating: 4,
    estimatedMinPrice: "9000",
    estimatedMaxPrice: "100000",
  },
  {
    id: 22,
    type: "jobAgent",
    logo: "assets/img/serProMatchLogo/logo-B2.png",
    firm: "Bright Recuriting Limited",
    serviceDescription:
      "To provide recuriting service for SMEs and startup companies",
    serviceArea: "Recuriting Services",
    rating: 4,
    estimatedMinPrice: "4000",
    estimatedMaxPrice: "80000",
  },
  {
    id: 23,
    type: "otherSerPro",
    logo: "assets/img/serProMatchLogo/logo-F.png",
    firm: "Forever Win Blockchain Consulting Limited",
    serviceDescription:
      "To provide Fintech advisory service for SMEs and startup companies",
    serviceArea: "HR Services",
    rating: 4,
    estimatedMinPrice: "2000",
    estimatedMaxPrice: "50000",
  },
];

export default serviceProviderList;
