import React, { useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  LinkedInLoginButton,
} from "react-social-login-buttons";

const UserLogin = () => {
  const [loginType, setLoginType] = useState("email");

  const handleLoginTypeChange = (type) => {
    setLoginType(type);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // your login logic goes here
  };

  function handleClick() {
    // alert("Your loign will be handled soon...");
  }

  return (
    <div>
      {/* Navigation Bar */}
      <NavBar />

      <div className="login-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2 className="login-heading">Welcome back</h2>
          <p>Please enter your login credentials</p>

          <div className="login-type">
            <button
              className={`login-type__button ${
                loginType === "email"
                  ? "login-type__button--active"
                  : "login-type__button--inactive"
              }`}
              onClick={() => handleLoginTypeChange("email")}
            >
              Email
            </button>
            <button
              className={`login-type__button ${
                loginType === "mobile"
                  ? "login-type__button--active"
                  : "login-type__button--inactive"
              }`}
              onClick={() => handleLoginTypeChange("mobile")}
            >
              Mobile
            </button>
          </div>

          <div className="form-group">
            <label htmlFor="username" className="form-label">
              {loginType === "email" ? "Email" : "Mobile"}
            </label>
            <input
              type={loginType === "email" ? "email" : "tel"}
              id="username"
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input type="password" id="password" className="form-input" />
          </div>
          <button type="submit" className="login-button">
            Sign in
          </button>

          <div className="signup-btn">
            <Link to="">Forgot password</Link>
            <Link to="/user-signup">Create an account</Link>
          </div>

          <div className="login-social">
            <GoogleLoginButton
              style={{ background: "#D14332", color: "white", width: "70%" }}
              onClick={handleClick}
              className="social-btn-text"
            />
            <FacebookLoginButton
              style={{ width: "70%" }}
              onClick={handleClick}
              className="social-btn-text"
            />
            <LinkedInLoginButton
              style={{ width: "70%" }}
              onClick={handleClick}
              className="social-btn-text"
            />
          </div>
        </form>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default UserLogin;
