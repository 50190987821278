import React from 'react'
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaRegCalendarAlt,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'

import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import enTranslactions from '../languages/en.json'
import zhTranslactions from '../languages/zh.json'
import LanguageSwitcher from '../components/LanguageSwitcher'

i18n.use(initReactI18next).init({
  lng: 'en', // default language
  fallbackLng: 'en', // fallback language
  resources: {
    en: {
      translation: enTranslactions,
    },
    zh: {
      translation: zhTranslactions,
    },
  },
})

const Footer = () => {
  const { t } = useTranslation()

  return (
    <>
      {/* ================== Footer Start ==================*/}
      <footer
        className="footer-area bg-cover mt-0 pd-top-120"
        style={{ backgroundImage: 'url("assets/img/bg/14.png")' }}
      >
        <div className="footer-subscribe">
          <div className="container">
            <div
              className="footer-subscribe-inner box-shadow style-2 bg-white p-0 bg-cover"
              style={{
                borderRadius: '20px',
                // background: "#f2f2f2",
                padding: '20px',
              }}
            >
              <div
                className="media bg-base-2"
                style={{
                  borderTopLeftRadius: '20px',
                  borderBottomLeftRadius: '20px',
                  background: '#f2f2f2',
                  padding: '20px',
                }}
              >
                {/* <div className="media-left me-3 align-self-xl-center">
                  <img src="assets/img/icon/40.svg" alt="img" />
                </div> */}
                <div className="media-body">
                  <h5 className="text-white">{t('footer.subscribeBox')}</h5>
                </div>
              </div>
              <div className="row align-self-center">
                <div className="col-lg-4">
                  <div className="border-1">
                    <input type="text" placeholder="Your Name" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="border-1">
                    <input type="text" placeholder="Your Email Address" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <a
                    className="btn w-100 btn-black sky border-radius-0"
                    href="#"
                  >
                    {t('footer.subscribe')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">{t('footer.contactUs')}</h4>
                <div className="widget widget_contact">
                  <ul className="details text-white">
                    <li>
                      <FaMapMarkerAlt className="sky" />
                      {t('footer.companyAddress')}
                    </li>
                    <li className="mt-3">
                      <FaPhoneAlt className="sky" />{' '}
                      {t('footer.companyContactPhone')}
                    </li>
                    <li className="mt-2">
                      <FaEnvelope className="sky" />{' '}
                      {t('footer.companyContactEmail')}
                    </li>
                  </ul>
                  <ul className="social-media mt-4">
                    <li>
                      <a href="#" className="sky">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="sky">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="sky">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="#" className="sky">
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">{t('footer.whoAreWe')}</h4>
                <ul>
                  <li className="sky" style={{ whiteSpace: 'pre-wrap' }}>
                    {t('footer.creradarIntro')}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">{t('footer.ourPlatform')}</h4>
                <ul>
                  <li className="sky">
                    <Link to="/home">
                      <FaChevronRight /> {t('footer.home')}
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/about">
                      <FaChevronRight /> {t('footer.aboutUs')}
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/service">
                      <FaChevronRight /> {t('footer.matchingService')}
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/marketplace">
                      <FaChevronRight /> {t('footer.marketplace')}
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/forum">
                      <FaChevronRight /> {t('footer.forum')}
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/news">
                      <FaChevronRight /> {t('footer.news')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">{t('footer.recentBlog')}</h4>
                <ul>
                  <li className="sky">
                    <div className="media">
                      <div className="media-body align-self-center">
                        <div className="post-info  mb-2">
                          <FaRegCalendarAlt className="sky" />
                          <span>March 11, 2023</span>
                        </div>
                        <h6 className="title sky mb-0">
                          <Link to="/forum-details">
                            The Most Challenges for Startups in Hong Kong
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li className="sky">
                    <div className="media">
                      <div className="media-body align-self-center">
                        <div className="post-info mb-2">
                          <FaRegCalendarAlt className="sky" />
                          <span>March 18, 2023</span>
                        </div>
                        <h6 className="title sky mb-0">
                          <Link to="/forum-details">
                            Proposed To Increase Startup Funding
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row footer-sec-area">
              <div className="col-md-4 align-self-center copyright">
                <p>{t('footer.copyright')}</p>
              </div>
              <div className="col-md-4 text-lg-end tnc-area">
                <a href="#">
                  {t('footer.terms')} &amp; {t('footer.condition')}
                </a>
                <a href="#">{t('footer.pricaryPolicy')}</a>
                <a href="#">{t('footer.contactUs')}</a>
              </div>

              <div className="col-md-4 language-drop-dwon">
                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer end ==================*/}
    </>
  )
}

export default Footer
