import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const MobileDemoArea = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className="about-area pd-top-80 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h6 className="sub-title">MOBILE APPLICATION</h6>
                <h2 className="title">Find Your Partners</h2>
                <p className="content mb-4">
                  From co-founders, advisors, mentors & entrepreneurs, CreRadar
                  provides you with all the means necessary to find the perfect
                  fit for your startup.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> Shared workload and responsibilities
                      </li>
                      <li>
                        <FaCheckCircle /> Diverse skillsets and perspectives
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> Increased motivation and support
                      </li>
                      <li>
                        <FaCheckCircle /> Improved networking and connections
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="content">
                  Team formation is crucial for the success of
                  your startup!
                </p>
                <Link className="btn btn-border-base" to="/">
                  Sign Up For Free <FaPlus />
                </Link>
              </div>
            </div>

            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div
                className="about-thumb-inner"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="main-img"
                  src="assets/img/mobile-demo/demo-SmartMatch.png"
                  alt="img"
                  style={{
                    height: "600px",
                    //   width: "",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default MobileDemoArea;
