import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { animated } from "react-spring";

import UserSignUpBreadcrumb from "./UserSignUpBreadcrumb";

const UserSignUpArea = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [emailUniqueErr, setEmailUniqueErr] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [passwordFormatErr, setPasswordFormatErr] = useState(false);
  const [rePassword, setRePassword] = useState("");
  const [rePasswordErr, setRePasswordErr] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [firstnameErr, setFirstnameErr] = useState(false);
  const [lastname, setLastname] = useState("");
  const [lastnameErr, setLastnameErr] = useState(false);
  const [birthday, setBirthday] = useState("");
  const [birthdayErr, setBirthdayErr] = useState(false);
  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState(false);
  const [country, setCountry] = useState("");
  const [countryErr, setCountryErr] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [phoneUniqueErr, setPhoneUniqueErr] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [linkedinURL, setLinkedinURL] = useState("");
  const [linkedinURLErr, setLinkedinURLErr] = useState(false);
  const [industry, setIndustry] = useState("");
  const [industryErr, setIndustryErr] = useState(false);
  const [accomplishment, setAccomplishment] = useState("");
  const [startupStage, setStartupStage] = useState("");
  const [startupStageErr, setStartupStageErr] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [isActiveErr, setIsActiveErr] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [userRoleErr, setUserRoleErr] = useState(false);
  const [matchingRole, setMatchingRole] = useState("");
  const [matchingRoleErr, setMatchingRoleErr] = useState(false);
  const [analysisSkill, setAnalysisSkill] = useState(false);
  const [businessPlanningSkill, setBusinessPlanningSkill] = useState(false);
  const [cryptoSkill, setCryptoSkill] = useState(false);
  const [communitiesSkill, setCommunitiesSkill] = useState(false);
  const [corporateStrategySkill, setCorporateStrategySkill] = useState(false);
  const [financeSkill, setFinanceSkill] = useState(false);
  const [fundraisingSkill, setFundraisingSkill] = useState(false);
  const [graphicsSkill, setGraphicsSkill] = useState(false);
  const [softwareEngineeringSkill, setSoftwareEngineeringSkill] =
    useState(false);
  const [hardwareEngineeringSkill, setHardwareEngineeringSkill] =
    useState(false);
  const [humanResourcesSkill, setHumanResourcesSkill] = useState(false);
  const [leadershipSkill, setLeadershipSkill] = useState(false);
  const [managementSkill, setManagementSkill] = useState(false);
  const [marketingSkill, setMarketingSkill] = useState(false);
  const [nftSkill, setNftSkill] = useState(false);
  const [operationsSkill, setOperationsSkill] = useState(false);
  const [programmingSkill, setProgrammingSkill] = useState(false);
  const [technicalSkill, setTechnicalSkill] = useState(false);
  const [teamBuildingSkill, setTeamBuildingSkill] = useState(false);
  const [web3Skill, setWeb3Skill] = useState(false);
  const [workExp, setWorkExp] = useState("");
  const [startupExp, setStartupExp] = useState("");

  const goStep = (step) => {
    let errors = [];
    setIsLoading(false);

    if (step == 2) {
      // setIsLoading(true)
      setEmailErr(false);
      setEmailUniqueErr(false);
      // setConfirmEmailErr(false);
      setPasswordErr(false);
      setPasswordFormatErr(false);
      setRePasswordErr(false);

      if (email == "") {
        errors.push("err");
        setEmailErr(true);
      }

      if (!validEmail(email)) {
        errors.push("err");
        setEmailErr(true);
      }

      if (password == "") {
        errors.push("err");
        setPasswordErr(true);
      } else if (
        !password.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^-])[A-Za-z\d@$!%*?&#^-]{8,}$/
        )
      ) {
        errors.push("err");
        setPasswordFormatErr(true);
      }

      if (rePassword == "") {
        errors.push("err");
        setRePasswordErr(true);
      } else if (
        !rePassword.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^-])[A-Za-z\d@$!%*?&#^-]{8,}$/
        )
      ) {
        errors.push("err");
        setPasswordFormatErr(true);
      }

      if (!errors.length) {
        // let data = {
        //   email: email,
        //   password: password,
        // };

        // checkUnique(data, (unique) => {
        //   console.log(unique);
        //   if (unique.status == "success") {
        //     setStep(step);
        //     setIsLoading(false);
        //   } else {
        //     if (unique.message == "Email already used") {
        //       setEmailUniqueErr(true);
        //     }

        setStep(step);
        setIsLoading(false);
      } else {
        setStep(step); // only uncomment when development
        setIsLoading(false);
      }
    } else if (step == 3) {
      // setIsLoading(true);
      setFirstnameErr(false);
      setLastnameErr(false);
      setBirthdayErr(false);
      setGenderErr(false);
      setPhoneErr(false);
      setPhoneUniqueErr(false);
      setCountryErr(false);
      setLinkedinURLErr(false);

      if (firstname == "") {
        errors.push("err");
        setFirstnameErr(true);
      }

      if (lastname == "") {
        errors.push("err");
        setLastnameErr(true);
      }

      if (birthday == "") {
        errors.push("err");
        setBirthdayErr(true);
      }

      if (gender == "") {
        errors.push("err");
        setGenderErr(true);
      }

      if (phone == "") {
        errors.push("err");
        setPhoneErr(true);
      }

      if (country == "") {
        errors.push("err");
        setCountryErr(true);
      }

      // if (!validPhone) {
      //   errors.push("err");
      //   setPhoneErr(true);
      // }

      if (linkedinURL == "") {
        errors.push("err");
        setLinkedinURLErr(true);
      }

      if (!errors.length) {
        // let data = {
        //   mobile: dialCode + phone.replace(/\s/g, ""),
        // };

        // checkUnique(data, (unique) => {
        //   console.log(unique);
        //   if (unique.status == "success") {
        //     setStep(step);
        //     setIsLoading(false);
        //   } else {

        //     if (unique.message == "Mobile already used") {
        //       setPhoneUniqueErr(true);
        //     }

        setStep(step);
        setIsLoading(false);
      } else {
        setStep(step); // only uncomment when development
        setIsLoading(false);
      }
    } else if (step == 4) {
      // setIsLoading(true);

      if (userRole == "" || userRole == null) {
        errors.push("err");
        setUserRoleErr(true);
        setIsLoading(false);
      }

      if (userRole == "founder") {
        // setIsLoading(true);
        setIndustryErr(false);
        setStartupStageErr(false);
        setIsActiveErr(false);

        if (industry == "") {
          errors.push("err");
          setIndustryErr(true);
        }

        if (startupStage == "") {
          errors.push("err");
          setStartupStageErr(true);
        }

        if (isActive == "") {
          errors.push("err");
          setIsActiveErr(true);
        }
      }

      if (!errors.length) {
        setStep(step);
        setIsLoading(false);
      } else {
        setStep(step); // only uncomment when development
        setIsLoading(false);
      }
    } else if (step == 5) {
      // setIsLoading(true);
      setMatchingRoleErr(false);

      if (matchingRole == "") {
        errors.push();
        setMatchingRoleErr(true);
      }

      if (!errors.length) {
        setStep(step);
        setIsLoading(false);
      } else {
        setStep(step); // only uncomment when development
        setIsLoading(false);
      }
    } else {
      setStep(step); // only uncomment when development
      setIsLoading(false);
    }
  };

  const validEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const submitForm = () => {
    // setIsLoading(true);
    setMatchingRoleErr(false);

    if (matchingRole == "") {
      // errors.push();
      setMatchingRoleErr(true);
    }

    // add logic checking ...
  };

  return (
    <div className="user-signup-area">
      <div className="signup-breadcrumb">
        <UserSignUpBreadcrumb title={"User Registration"} />
      </div>

      {/* Web Signup - Step 1 Start */}
      {step === 1 && (
        <div className="signup-container">
          <div className="sign-up-area row">
            <div className="bus-quote col-lg-4">
              <img src="assets/img/user-signup/busSucQuote1.png" alt="img" />
            </div>

            <div className="sign-up-details col-lg-8">
              {/* Step 1 - details */}
              <div className="step-btn-area">
                <div className="step-number-btn">1</div>
                <div className="step-btn-name">Create Account</div>

                <div className="step-number-btn">2</div>
                <div className="step-btn-name">Personal Information</div>

                <div className="step-number-btn">3</div>
                <div className="step-btn-name">Your Role?</div>

                <div className="step-number-btn">4</div>
                <div className="step-btn-name">Looking For?</div>

                <div className="step-number-btn">5</div>
                <div className="step-btn-name">Your Skills And Experiences</div>
              </div>

              <div className="sign-up-detail-continer">
                <div className="signup-step-one-area">
                  <div className="form-group">
                    <label htmlFor="" className="">
                      *Email Address
                    </label>
                    <input
                      id="username"
                      placeholder="i.e. kenwong@gmail.com"
                      className="form-input"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                    {emailErr && (
                      <p className="err-msg">Please enter a valid email</p>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="password">*Password</label>
                        <input
                          type="password"
                          id="password"
                          className="form-input password"
                          value={password}
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                          required
                        />
                        <p>
                          (Password must contain at least 8 alphanumeric
                          characters and with at least one uppercase and one
                          special character (?!@$%&*))
                        </p>

                        {passwordErr && (
                          <p className="err-msg">Please enter password</p>
                        )}
                        {passwordFormatErr && (
                          <p className="err-msg">
                            Password must contain at least 8 alphanumeric
                            characters and with at least one uppercase and one
                            special character (?!@$%&*)
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label htmlFor="password">*Re-password</label>
                        <input
                          type="password"
                          id="password"
                          className="form-input password"
                          value={rePassword}
                          onChange={(event) => {
                            setRePassword(event.target.value);
                          }}
                          required
                        />

                        <p>(Please confirm your password again)</p>

                        {passwordErr && (
                          <p className="err-msg">Please enter password</p>
                        )}
                        {passwordFormatErr && (
                          <p className="err-msg">
                            Password must contain at least 8 alphanumeric
                            characters and with at least one uppercase and one
                            special character (?!@$%&*)
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bottom-btn">
                <NextButton onClick={() => goStep(2)}>Next</NextButton>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Web Signup - Step 1 End */}

      {/* Web Signup - Step 2 Start */}
      {step === 2 && (
        <div className="signup-container">
          <div className="sign-up-area row">
            <div className="bus-quote col-lg-4">
              <img src="assets/img/user-signup/busSucQuote1.png" alt="img" />
            </div>

            <div className="sign-up-details col-lg-8">
              {/* Step 2 - details */}
              <div className="step-btn-area">
                <div className="step-num-btn-done">✓</div>
                <div className="step-btn-name">Create Account</div>

                <div className="step-number-btn">2</div>
                <div className="step-btn-name">Personal Information</div>

                <div className="step-number-btn">3</div>
                <div className="step-btn-name">Your Role?</div>

                <div className="step-number-btn">4</div>
                <div className="step-btn-name">Looking For?</div>

                <div className="step-number-btn">5</div>
                <div className="step-btn-name">Your Skills And Experiences</div>
              </div>

              <div className="sign-up-detail-continer">
                <div className="image-container">
                  <div className="signup-user-img-area">Edit Image</div>
                </div>

                <div className="signup-step-two-area row">
                  <div className="area-one col-lg-6">
                    <div className="form-group">
                      <label htmlFor="">First Name</label>
                      <input
                        id="first-name"
                        placeholder="i.e. Ken"
                        className="form-input"
                        value={firstname}
                        onChange={(event) => {
                          setFirstname(event.target.value);
                        }}
                      />
                      {firstnameErr && (
                        <p className="err-msg">Please enter your first name</p>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="given-name">Last Name</label>
                      <input
                        id="last-name"
                        placeholder="i.e. Wong"
                        className="form-input"
                        value={lastname}
                        onChange={(event) => {
                          setLastname(event.target.value);
                        }}
                      />
                      {lastnameErr && (
                        <p className="err-msg">Please enter your last name</p>
                      )}
                    </div>

                    <div className="signup-search-box">
                      <label htmlFor="birth-year">Age (range)</label>

                      <select
                        // type="date"
                        id="birth-year"
                        name="birth-year"
                        value={birthday}
                        onChange={(event) => setBirthday(event.target.value)}
                      >
                        <option value="">Please select your age range</option>
                        <option value="less18">Less than 18</option>
                        <option value="18to25">19 to 30</option>
                        <option value="26to30">30 to 40</option>
                        <option value="26to30">41 to 50</option>
                        <option value="26to30">51 or order</option>
                      </select>

                      {birthdayErr && (
                        <p className="err-msg">Please enter your birth date</p>
                      )}
                    </div>

                    <div className="signup-search-box">
                      <label htmlFor="business-type">Gender</label>
                      <select
                        id="gender-type"
                        name="gender-type"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="">Please select your gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {genderErr && (
                        <p className="err-msg">Please select your gender</p>
                      )}
                    </div>
                  </div>

                  <div className="area-one col-lg-6">
                    <div className="form-group">
                      <label htmlFor="" className="">
                        Mobile No.
                      </label>
                      <input
                        id="username"
                        // placeholder="(852) 8888 8888"
                        className="form-input"
                        value={phone}
                        onChange={(event) => {
                          setPhone(event.target.value);
                        }}
                      />
                      {phoneErr && (
                        <p className="err-msg">Please enter your mobile</p>
                      )}
                    </div>

                    <div className="signup-search-box">
                      <label htmlFor="country-type">Country</label>
                      <select
                        id="country-type"
                        name="country-type"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option value="">Please select your country</option>
                        <option value="hk">Hong Kong</option>
                        <option value="china">China</option>
                      </select>
                      {countryErr && (
                        <p className="err-msg">Please select your country</p>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="" className="">
                        LinkedIn URL
                      </label>
                      <input
                        id="username"
                        placeholder="i.e. https://www.linkedin.com/in/kenwong/"
                        className="form-input"
                        value={linkedinURL}
                        onChange={(event) => {
                          setLinkedinURL(event.target.value);
                        }}
                      />
                      {linkedinURLErr && (
                        <p className="err-msg">
                          Please enter your LinkedIn URL
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bottom-btn">
                <BackButton onClick={() => goStep(1)}>Back</BackButton>
                <NextButton onClick={() => goStep(3)}>Next</NextButton>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Web Signup - Step 2 End */}

      {/* Web Signup - Step 3 Start */}
      {step === 3 && (
        <div className="signup-container">
          <div className="sign-up-area row">
            <div className="bus-quote col-lg-4">
              <img src="assets/img/user-signup/busSucQuote1.png" alt="img" />
            </div>

            <div className="sign-up-details col-lg-8">
              {/* Step 3 - details */}
              <div className="step-btn-area">
                <div className="step-num-btn-done">✓</div>
                <div className="step-btn-name">Create Account</div>

                <div className="step-num-btn-done">✓</div>
                <div className="step-btn-name">Personal Information</div>

                <div className="step-number-btn">3</div>
                <div className="step-btn-name">Your Role?</div>

                <div className="step-number-btn">4</div>
                <div className="step-btn-name">Looking For?</div>

                <div className="step-number-btn">5</div>
                <div className="step-btn-name">Your Skills And Experiences</div>
              </div>

              <div className="sign-up-detail-continer">
                <div className="signup-step-three-area">
                  <table className="role-table">
                    <thead>
                      <tr>
                        <th>Role</th>
                        <th>Definition</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Founder</td>
                        <td>
                          A person who establishes or creates a company or
                          organization, often with the goal of developing and
                          scaling a new product, service or innovation.
                        </td>
                      </tr>
                      <tr>
                        <td>Mentor / Advisor</td>
                        <td>
                          A person who gives advice or leads a group, like an
                          advisor to the school yearbook club, guiding its
                          members in putting together the pages, but not doing
                          the work for them.
                        </td>
                      </tr>
                      <tr>
                        <td>Team member</td>
                        <td>
                          A team member of a startup always plays an important
                          role to the business success.
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="signup-search-box user-role-qs">
                    <label htmlFor="business-type">What is your role?</label>
                    <select
                      id="role-type"
                      name="role-type"
                      value={userRole}
                      onChange={(e) => setUserRole(e.target.value)}
                    >
                      <option value="">Please select your role</option>

                      <option value="founder">Founder</option>
                      <option value="advisor">Mentor / Advisor</option>
                      <option value="member">Team member</option>
                    </select>

                    {userRoleErr && (
                      <p className="err-msg">Please select your role</p>
                    )}
                  </div>

                  {userRole == "founder" && (
                    <div>
                      <div className="signup-search-box">
                        <label htmlFor="business-type">
                          Business Industry (*the most representative of your
                          startup)
                        </label>
                        <select
                          id="industry-type"
                          name="industry-type"
                          value={industry}
                          onChange={(e) => setIndustry(e.target.value)}
                        >
                          <option value="">
                            Please select your business industry
                          </option>
                          <option value="technology">
                            Innovation and technology
                          </option>
                          <option value="food">Food and beverage</option>
                          <option value="retail">Retail sales</option>
                          <option value="education">
                            Education and tutoring
                          </option>
                          <option value="service">Services</option>
                          <option value="cultural">
                            Cultural and creative industries
                          </option>
                          <option value="environment">
                            Environmental industry
                          </option>
                        </select>

                        {industryErr && (
                          <p className="err-msg">
                            Please select your business industry
                          </p>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="">
                          Accomplishments (if any) (*start with the most recent
                          accomplishments):
                        </label>
                        <input
                          id="work-exp"
                          placeholder="i.e. The winner of HKFYG; HKSTP's Incubation  ... etc."
                          className="form-input"
                          value={accomplishment}
                          onChange={(event) => {
                            setAccomplishment(event.target.value);
                          }}
                        />
                      </div>

                      <div className="signup-search-box">
                        <label htmlFor="business-type">
                          Your stage of startup?
                        </label>
                        <select
                          id="industry-type"
                          name="industry-type"
                          value={startupStage}
                          onChange={(e) => setStartupStage(e.target.value)}
                        >
                          <option value="">
                            Please select your startup stage
                          </option>
                          <option value="concepts">
                            Concepts and Research
                          </option>
                          <option value="traction">Traction</option>
                          <option value="launch">Launch</option>
                          <option value="pre-revenue">Pre-revenue</option>
                          <option value="early">Early</option>
                          <option value="growth">Growth</option>
                          <option value="scale">Scale</option>
                          <option value="exit">Exit</option>
                        </select>

                        {startupStageErr && (
                          <p className="err-msg">
                            Please select your startup stage
                          </p>
                        )}
                      </div>

                      <div className="signup-search-box">
                        <label htmlFor="business-type">
                          Are you actively working on a startup?
                        </label>
                        <p
                          style={{
                            color: "black",
                            fontSize: "14px",
                          }}
                        >
                          "Actively" means 10+ hours a week. We will show you
                          different content based on whether you are currently
                          working on a startup or whether you are interested in
                          doing so in the future. *You can come back and change
                          this at any time.
                        </p>
                        <select
                          id="industry-type"
                          name="industry-type"
                          value={isActive}
                          onChange={(e) => setIsActive(e.target.value)}
                        >
                          <option value="">Please select your type</option>
                          <option value="active">Yes</option>
                          <option value="inactive">No</option>
                        </select>

                        {isActiveErr && (
                          <p className="err-msg">Please select your type</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="bottom-btn">
                <BackButton onClick={() => goStep(2)}>Back</BackButton>
                <NextButton onClick={() => goStep(4)}>Next</NextButton>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Web Signup - Step 3 End */}

      {/* Web Signup - Step 4 Start */}
      {step === 4 && (
        <div className="signup-container">
          <div className="sign-up-area row">
            <div className="bus-quote col-lg-4">
              <img src="assets/img/user-signup/busSucQuote1.png" alt="img" />
            </div>

            <div className="sign-up-details col-lg-8">
              {/* Step 4 - details */}
              <div className="step-btn-area">
                <div className="step-num-btn-done">✓</div>
                <div className="step-btn-name">Create Account</div>

                <div className="step-num-btn-done">✓</div>
                <div className="step-btn-name">Personal Information</div>

                <div className="step-num-btn-done">✓</div>
                <div className="step-btn-name">Your Role?</div>

                <div className="step-number-btn">4</div>
                <div className="step-btn-name">Looking For?</div>

                <div className="step-number-btn">5</div>
                <div className="step-btn-name">Your Skills And Experiences</div>
              </div>

              <div className="sign-up-detail-continer">
                <div className="signup-step-four-area">
                  <table className="role-table">
                    <thead>
                      <tr>
                        <th>Role</th>
                        <th>Definition</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Co-Founder</td>
                        <td>
                          A person who establishes or creates a company or
                          organization, often with the goal of developing and
                          scaling a new product, service or innovation.
                        </td>
                      </tr>
                      <tr>
                        <td>Mentor / Advisor</td>
                        <td>
                          A person who gives advice or leads a group, like an
                          advisor to the school yearbook club, guiding its
                          members in putting together the pages, but not doing
                          the work for them.
                        </td>
                      </tr>
                      <tr>
                        <td>Team member</td>
                        <td>
                          A team member of a startup always plays an important
                          role to the business success.
                        </td>
                      </tr>
                      <tr>
                        <td>Startups</td>
                        <td>
                          A newly established business that aim to introduce
                          innovative products or services into the market.
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="signup-search-box user-role-qs">
                    <label htmlFor="business-type">
                      What kind of role do you want to match?
                    </label>
                    <select
                      id="role-type"
                      name="role-type"
                      value={matchingRole}
                      onChange={(e) => setMatchingRole(e.target.value)}
                    >
                      <option value="">
                        Please select the role you want to match
                      </option>

                      <option value="cofounder">Co-founder</option>
                      <option value="advisor">Mentor / Advisor</option>
                      <option value="member">Team member</option>
                      <option value="investor">Startup</option>
                    </select>

                    {matchingRoleErr && (
                      <p className="err-msg">
                        Please select the role you want to match
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="bottom-btn">
                <BackButton onClick={() => goStep(3)}>Back</BackButton>
                <NextButton onClick={() => goStep(5)}>Next</NextButton>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Web Signup - Step 4 End */}

      {/* Web Signup - Step 5 Start */}
      {step === 5 && (
        <div className="signup-container">
          <div className="sign-up-area row">
            <div className="bus-quote col-lg-4">
              <img src="assets/img/user-signup/busSucQuote1.png" alt="img" />
            </div>

            <div className="sign-up-details col-lg-8">
              {/* Step 5 - details */}
              <div className="step-btn-area">
                <div className="step-num-btn-done">✓</div>
                <div className="step-btn-name">Create Account</div>

                <div className="step-num-btn-done">✓</div>
                <div className="step-btn-name">Personal Information</div>

                <div className="step-num-btn-done">✓</div>
                <div className="step-btn-name">Your Role?</div>

                <div className="step-num-btn-done">✓</div>
                <div className="step-btn-name">Looking For?</div>

                <div className="step-number-btn">5</div>
                <div className="step-btn-name">Your Skills And Experiences</div>
              </div>

              <div className="sign-up-detail-continer signup-step-five-area">
                <div className="skill-area">
                  <div>What kind of skills do you have?</div>
                  <div className="skills-choice row">
                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={analysisSkill}
                            onChange={(e) => setAnalysisSkill(e.target.value)}
                          />
                          {""} Analysis
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={businessPlanningSkill}
                            onChange={(e) =>
                              setBusinessPlanningSkill(e.target.value)
                            }
                          />
                          {""} Business Planning / Processes
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={cryptoSkill}
                            onChange={(e) => setCryptoSkill(e.target.value)}
                          />
                          {""} Crypto
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={communitiesSkill}
                            onChange={(e) =>
                              setCommunitiesSkill(e.target.value)
                            }
                          />
                          {""} Communities
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={corporateStrategySkill}
                            onChange={(e) =>
                              setCorporateStrategySkill(e.target.value)
                            }
                          />
                          {""} Corporate Strategy
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={financeSkill}
                            onChange={(e) => setFinanceSkill(e.target.value)}
                          />
                          {""} Finance
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={fundraisingSkill}
                            onChange={(e) =>
                              setFundraisingSkill(e.target.value)
                            }
                          />
                          {""} Fundraising
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={graphicsSkill}
                            onChange={(e) => setGraphicsSkill(e.target.value)}
                          />
                          {""} Graphics / Design
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={softwareEngineeringSkill}
                            onChange={(e) =>
                              setSoftwareEngineeringSkill(e.target.value)
                            }
                          />
                          {""} Software Engineering
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={hardwareEngineeringSkill}
                            onChange={(e) =>
                              setHardwareEngineeringSkill(e.target.value)
                            }
                          />
                          {""} Hardware Engineering
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={humanResourcesSkill}
                            onChange={(e) =>
                              setHumanResourcesSkill(e.target.value)
                            }
                          />
                          {""} Human Resources
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={leadershipSkill}
                            onChange={(e) => setLeadershipSkill(e.target.value)}
                          />
                          {""} Leadership
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={managementSkill}
                            onChange={(e) => setManagementSkill(e.target.value)}
                          />
                          {""} Managemnt
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={marketingSkill}
                            onChange={(e) => setMarketingSkill(e.target.value)}
                          />
                          {""} Marketing
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={nftSkill}
                            onChange={(e) => setNftSkill(e.target.value)}
                          />
                          {""} NFT
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={operationsSkill}
                            onChange={(e) => setOperationsSkill(e.target.value)}
                          />
                          {""} Operations
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={programmingSkill}
                            onChange={(e) =>
                              setProgrammingSkill(e.target.value)
                            }
                          />
                          {""} Programming
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={technicalSkill}
                            onChange={(e) => setTechnicalSkill(e.target.value)}
                          />
                          {""} Technical
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={teamBuildingSkill}
                            onChange={(e) =>
                              setTeamBuildingSkill(e.target.value)
                            }
                          />
                          {""} Team Building
                        </label>
                      </div>
                    </div>

                    <div className="area-one col-lg-3">
                      <div className="skills-input">
                        <label>
                          <input
                            type="checkbox"
                            value={web3Skill}
                            onChange={(e) => setWeb3Skill(e.target.value)}
                          />
                          {""} Web 3.0
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="experience-area">
                  <div className="form-group single-input-inner style-bg">
                    <label htmlFor="">
                      Work Experiences (if any) (*start with the recent
                      experiences):
                    </label>
                    <textarea
                      id="work-exp"
                      placeholder="i.e. 3 years of Software Developer; 2 years of Frontend Developer ... etc."
                      className="form-input "
                      value={workExp}
                      onChange={(event) => {
                        setWorkExp(event.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group single-input-inner style-bg">
                    <label htmlFor="">
                      Your startup experiences (if any) (*start with the recent
                      experiences):
                    </label>
                    <textarea
                      id="work-exp"
                      placeholder="i.e. 2 year of Health Care App Development;  1 year of Financial Website Development  ... etc."
                      className="form-input"
                      value={startupExp}
                      onChange={(event) => {
                        setStartupExp(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="bottom-btn">
                <BackButton onClick={() => goStep(4)}>Back</BackButton>
                <NextButton onClick={() => submitForm()}>Submit</NextButton>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Web Signup - Step 5 End */}
    </div>
  );
};

const BackButton = styled(animated.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 75px;
  padding: 0 20px;
  margin: 0 10px;
  background-color: #ebeaed;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;

  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #e6e6e6;
  }
`;

const NextButton = styled(animated.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 75px;
  padding: 0 20px;
  margin: 0 10px;
  color: white;
  background-color: #0b0d15;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;

  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  &:hover {
    color: black;
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #e6e6e6;
  }
`;

export default UserSignUpArea;
