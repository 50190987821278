import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import AOS from "aos";
import "aos/dist/aos.css";
import Home from "./pages/Home";
import UserLogin from "./pages/UserLogin";
import About from "./pages/About";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Advisers from "./pages/Advisers";
import Startups from "./pages/Startups";
import Marketplace from "./pages/Marketplace";
import ServiceProviderMatching from "./pages/ServiceProviderMatching";
import Forum from "./pages/Forum";
import ForumDetails from "./pages/ForumDetails";
import News from "./pages/News";
import Contact from "./pages/Contact";
import UserSignUp from "./pages/UserSignUp";
import SerProSignUp from "./pages/SerProSignUp";
import MatchedCofounder from "./pages/MatchedCofounder";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });

    AOS.refresh();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/user-login" element={<UserLogin />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/service-details" element={<ServiceDetails />} />
        <Route exact path="/matched-cofounder" element={<MatchedCofounder />} />
        <Route exact path="/advisers" element={<Advisers />} />
        <Route exact path="/startups" element={<Startups />} />
        <Route exact path="/marketplace" element={<Marketplace />} />
        <Route
          exact
          path="/service-provider-matching"
          element={<ServiceProviderMatching />}
        />
        <Route exact path="/forum" element={<Forum />} />
        <Route exact path="/forum-details" element={<ForumDetails />} />
        <Route exact path="/news" element={<News />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/user-signup" element={<UserSignUp />} />
        <Route
          exact
          path="/service-provider-signup"
          element={<SerProSignUp />}
        />
      </Routes>

      <ScrollToTop smooth color="#246BFD" />
    </BrowserRouter>
  );
}

export default App;
