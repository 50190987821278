import { createReducer } from "@reduxjs/toolkit";
import { loginThunk, fbThunk, googleThunk, updateProfile } from "./thunks";
import { IAuthState, MyJwtPayload } from "./state";
import jwtDecode from "jwt-decode";

const initialState = () => {
  let data = {
    isLoggedIn: false,
    displayName: "",
    role: "",
    username: "",
    email: "",
    mobile_no: "",
  };

  let token = localStorage.getItem("token");
  //   console.log("token :", token);

  if (token && isJson(token)) {
    const jwtPayload = jwtDecode(token);
    data.isLoggedIn = true;
    data.displayName = jwtPayload.displayName;
    data.role = jwtPayload.role;
    data.username = jwtPayload.username;
    data.email = jwtPayload.email;
    data.mobile_no = jwtPayload.mobile_no;
  }

  return data;
};

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
}

const authReducer = createReducer(initialState(), (build) => {
  // success login handling:
  build.addCase(loginThunk.fulfilled, (state, action) => {
    handleToken(state, action);
  });
  // fail login handling:
  build.addCase(loginThunk.rejected, (state, action) => {
    state.isLoggedIn = false;
  });
  // update profile handling:
  build.addCase(updateProfile.fulfilled, (state, action) => {
    handleToken(state, action);
  });
  // success fb login handling:
  build.addCase(fbThunk.fulfilled, (state, action) => {
    handleToken(state, action);
  });
  // fail fb login handling:
  build.addCase(fbThunk.rejected, (state, action) => {
    state.isLoggedIn = false;
  });
  // success google login handling:
  build.addCase(googleThunk.fulfilled, (state, action) => {
    handleToken(state, action);
  });
  // fail google login handling:
  build.addCase(googleThunk.rejected, (state, action) => {
    state.isLoggedIn = false;
  });
});

const handleToken = (state, action) => {
  const token = action.payload;
  localStorage.setItem("token", token);

  const jwtPayload = jwtDecode(token);
  state.isLoggedIn = true;
  state.displayName = jwtPayload.displayName;
  state.role = jwtPayload.role;
  state.username = jwtPayload.username;
  state.email = jwtPayload.email;
  state.mobile_no = jwtPayload.mobile_no;
};

export default authReducer;
