let adviserList = [
  {
    id: 1,
    userImg: "assets/img/smart-matching/user-icon/user-lisa-chen.png",
    candidateName: "Lisa Chen",
    industry: "Innovation and Technology",
    experience: "0-3 Years",
    jobType: "Freelancers",
    briefIntro: "A previous design team manager at Google and Facebook",
    salary: "$800 / hours",
    addclassNameBookmark: true,
    label: true,
    statuslabel: "FEATURED",
  },
  {
    id: 2,
    userImg: "assets/img/smart-matching/user-icon/user-sarah-lee.png",
    candidateName: "Sarah Lee",
    industry: "Food and Beverage",
    experience: "3.5 Years",
    jobType: "Full Time",
    briefIntro: "Experienced sous chef at a Michelin-starred restaurant",
    salary: "$350 / hours",
    addclassNameBookmark: true,
    label: false,
  },
  {
    id: 3,
    userImg: "assets/img/smart-matching/user-icon/user-steven-wong.png",
    candidateName: "Steven Wong",
    industry: "Retail Sales",
    experience: "4 Years",
    jobType: "Freelancers",
    briefIntro: "Sales associate with experience in luxury goods",
    salary: "$280 / hours",
    addclassNameBookmark: false,
    label: true,
    statuslabel: "URGENT",
  },

  {
    id: 4,
    userImg: "assets/img/smart-matching/user-icon/user-michael-park.png",
    candidateName: "Michael Park",
    industry: "Education and Tutoring",
    experience: "2 Years",
    jobType: "Freelancers",
    briefIntro: "Certified teacher with experience in ESL and special education",
    salary: "$240 / hours",
    addclassNameBookmark: false,
    label: false,
  },
  {
    id: 5,
    userImg: "assets/img/smart-matching/user-icon/user-cindy-cheung.png",
    candidateName: "Cindy Cheung",
    industry: "Services",
    experience: "2 Years",
    jobType: "Freelancers",
    briefIntro: "Handyman with experience in carpentry and plumbing",
    salary: "$198 / hours",
    addclassNameBookmark: false,
    label: false,
  },
  {
    id: 6,
    userImg: "assets/img/smart-matching/user-icon/user-john-tsang.png",
    candidateName: "John Tsang",
    industry: "Cultural and Creative Industries",
    experience: "7 Years",
    jobType: "Full Time",
    briefIntro: "Graphic designer with experience in branding and typography",
    salary: "$299 / hours",
    addclassNameBookmark: true,
    label: false,
  },
  {
    id: 7,
    userImg: "assets/img/smart-matching/user-icon/user-eric-lui.png",
    candidateName: "Eric Lui",
    industry: "Environmental Industry",
    experience: "4 Years",
    jobType: "Freelancers",
    briefIntro: "Environmental scientist with experience in sustainability",
    salary: "$310 / hours",
    addclassNameBookmark: true,
    label: false,
  },
  {
    id: 8,
    userImg: "assets/img/smart-matching/user-icon/user-daniel-wong.png",
    candidateName: "Daniel Wong",
    industry: "Innovation and Technology",
    experience: "4.5 Years",
    jobType: "Freelancers",
    briefIntro: "Software engineer with experience in artificial intelligence",
    salary: "$450 / hours",
    addclassNameBookmark: false,
    label: false,
  },
  {
    id: 9,
    userImg: "assets/img/smart-matching/user-icon/user-hannah-chu.png",
    candidateName: "Hannah Chu",
    industry: "Food and Beverage",
    experience: "7 Years",
    jobType: "Freelancers",
    briefIntro: "Pastry chef with experience in French patisserie",
    salary: "$300 / hours",
    addclassNameBookmark: false,
    label: false,
  },
];

export default adviserList;
