import React, { useState, useEffect } from "react";
import { animated } from "react-spring";
import styled from "styled-components";
import serviceProviderList from "../scripts/serviceProviderList";
import {
  Col,
  Row,
  Collapse,
  Input,
  Label,
  CardBody,
  Modal,
  ModalBody,
} from "reactstrap";

import { useTranslation } from "react-i18next";

const SerProMatchArea = () => {
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { t } = useTranslation();

  const [industryToggle, setIndustryToggle] = useState(true);
  const [ratingToggle, setRatingToggle] = useState(true);

  const [accountant, setAccountant] = useState("");
  const [isAccountantChecked, setIsAccountantChecked] = useState(true);
  const [auditor, setAuditor] = useState("");
  const [isAuditorChecked, setIsAuditorChecked] = useState(true);
  const [comSec, setComSec] = useState("");
  const [isComSecChecked, setIsComSecChecked] = useState(true);
  const [marketing, setMarketing] = useState("");
  const [isMarketingChecked, setIsMarketingChecked] = useState(true);
  const [legalAdvisor, setLegalAdvisor] = useState("");
  const [isLegalAdvisorChecked, setIsLegalAdvisorChecked] = useState(true);
  const [finAdvisor, setFinAdvisor] = useState("");
  const [isFinAdvisorChecked, setIsFinAdvisorChecked] = useState(true);
  const [hrAdvisor, setHrAdvisor] = useState("");
  const [isHrAdvisorChecked, setIsHrAdvisorChecked] = useState(true);
  const [jobAgent, setJobAgent] = useState("");
  const [isJobAgentChecked, setIsJobAgentChecked] = useState(true);
  const [otherSerPro, setOtherSerPro] = useState("");
  const [isOtherSerProChecked, setIsOtherSerProChecked] = useState(true);

  const [isRateChecked, setIsRateChecked] = useState(true);

  const [oneStar, setOneStar] = useState("");
  const [twoStar, setTwoStar] = useState("");
  const [threeStar, setThreeStar] = useState("");
  const [fourStar, setFourStar] = useState("");
  const [fiveStar, setFiveStar] = useState("");

  const [isOneStarChecked, setIsOneStarChecked] = useState(true);
  const [isTwoStarChecked, setIsTwoStarChecked] = useState(true);
  const [isThreeStarChecked, setIsThreeStarChecked] = useState(true);
  const [isFourStarChecked, setIsFourStarChecked] = useState(true);
  const [isFiveStarChecked, setIsFiveStarChecked] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleAccountantOnChange = () => {
    setIsAccountantChecked(!isAccountantChecked);
    if (isAccountantChecked) {
      matchServiceProviders("accountant");
    } else {
      setAccountant("");
    }
  };

  const handleAuditorOnChange = () => {
    setIsAuditorChecked(!isAuditorChecked);
    if (isAuditorChecked) {
      matchServiceProviders("auditor");
    } else {
      setAuditor("");
    }
  };

  const handleComSecOnChange = () => {
    setIsComSecChecked(!isComSecChecked);
    if (isComSecChecked) {
      matchServiceProviders("comSec");
    } else {
      setComSec("");
    }
  };

  const handleMarketingOnChange = () => {
    setIsMarketingChecked(!isMarketingChecked);
    if (isMarketingChecked) {
      matchServiceProviders("marketing");
    } else {
      setMarketing("");
    }
  };

  const handleLegalAdvisorOnChange = () => {
    setIsLegalAdvisorChecked(!isLegalAdvisorChecked);
    if (isLegalAdvisorChecked) {
      matchServiceProviders("legalAdvisor");
    } else {
      setLegalAdvisor("");
    }
  };

  const handleFinAdvisorOnChange = () => {
    setIsFinAdvisorChecked(!isFinAdvisorChecked);
    if (isFinAdvisorChecked) {
      matchServiceProviders("financialAdvisor");
    } else {
      setFinAdvisor("");
    }
  };

  const handleHrAdvisorOnChange = () => {
    setIsHrAdvisorChecked(!isHrAdvisorChecked);
    if (isHrAdvisorChecked) {
      matchServiceProviders("hrAdvisor");
    } else {
      setHrAdvisor("");
    }
  };

  const handleJobAgentOnChange = () => {
    setIsJobAgentChecked(!isJobAgentChecked);
    if (isJobAgentChecked) {
      matchServiceProviders("jobAgent");
    } else {
      setJobAgent("");
    }
  };

  const handleOtherSerProOnChange = () => {
    setIsOtherSerProChecked(!isOtherSerProChecked);
    if (isOtherSerProChecked) {
      matchServiceProviders("otherServices");
    } else {
      setOtherSerPro("");
    }
  };

  const handleOneStarOnChange = () => {
    setIsOneStarChecked(!isOneStarChecked);
    if (isOneStarChecked) {
      starFiltering(1);
    }
  };

  const handleTwoStarOnChange = () => {
    setIsTwoStarChecked(!isTwoStarChecked);
  };

  const handleThreeStarOnChange = () => {
    setIsThreeStarChecked(!isThreeStarChecked);
  };

  const handleFourStarOnChange = () => {
    setIsFourStarChecked(!isFourStarChecked);
  };

  const handleFiveStarOnChange = () => {
    setIsFiveStarChecked(!isFiveStarChecked);
  };

  // ---------------------------- Table setting start ----------------------------
  const tableStyle = {
    borderSpacing: "0 10px",
  };

  const tableContextStyle = {
    marginBottom: "10px",
  };

  const headerStyle = {
    borderTop: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    color: "black",
    fontSize: "16px",
  };

  const cellStyles = {
    logo: { width: "8%" },
    firm: { width: "17%" },
    serviceDescription: { width: "36%" },
    serviceArea: { width: "15%" },
    rating: { width: "16%" },
    viewMore: { width: "4%" },
    quote: { width: "4%" },
  };

  const hideOnMobile = {
    // hide "Service Description" column for screens narrower than 600px
    city: {
      display: "none",
      "@media (min-width: 600px)": {
        display: "table-cell",
      },
    },
  };
  // ---------------------------- Table setting end ----------------------------

  // ---------------------------- Pagination handling start ----------------------------
  function handlePageChange(page) {
    setCurrentPage(page);
    setOffset((page - 1) * limit);

    // Scroll to the top of the page:
    // window.scrollTo(0, 0)
    const { innerHeight } = window;
    const isMobile = innerHeight > 0 && innerHeight < 768;
    const topOffset = isMobile ? 64 : 0;
    window.scrollTo(0, topOffset);
  }

  const totalPages = Math.ceil(serviceProviderList.length / limit);

  const pagesToShow = 5;
  let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

  if (endPage - startPage < pagesToShow - 1) {
    startPage = Math.max(1, endPage - pagesToShow + 1);
  }

  const pageButtons = [];

  if (startPage > 1) {
    pageButtons.push(
      <Button key="start-ellipsis" disabled className="page-btn">
        ...
      </Button>
    );
  }

  for (let i = startPage; i <= endPage; i++) {
    pageButtons.push(
      <Button
        key={i}
        disabled={currentPage === i}
        onClick={() => handlePageChange(i)}
        className="page-btn"
      >
        {i}
      </Button>
    );
  }

  if (endPage < totalPages) {
    pageButtons.push(
      <Button key="end-ellipsis" disabled className="page-btn">
        ...
      </Button>
    );
  }
  // ---------------------------- Pagination handling end ----------------------------

  const currencyFormat = (str) => {
    if (str) {
      str = str.toString();
      return str.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return "";
    }
  };

  const matchServiceProviders = (type) => {
    console.log("type:", type);

    if (type == "accountant") {
      setAccountant(type);
    }

    if (type == "auditor") {
      setAuditor(type);
    }
    if (type == "comSec") {
      setComSec(type);
    }
    if (type == "marketing") {
      setMarketing(type);
    }
    if (type == "legalAdvisor") {
      setLegalAdvisor(type);
    }
    if (type == "financialAdvisor") {
      setFinAdvisor(type);
    }
    if (type == "hrAdvisor") {
      setHrAdvisor(type);
    }
    if (type == "jobAgent") {
      setJobAgent(type);
    }
    if (type == "otherServices") {
      setOtherSerPro(type);
    }
  };

  const ServiceProviderRow = ({ data }) => {
    return (
      <>
        <td
          style={{ ...cellStyles.logo, ...hideOnMobile.logo }}
          className="hide-on-mobile"
        >
          <img
            src={data.logo}
            alt="img"
            style={{
              borderRadius: "50%",
              width: "100%",
              height: "100%",
            }}
          />
        </td>

        <td style={cellStyles.firm}>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">{data.firm}</div>
            <div className="col-md-1"></div>
          </div>
        </td>

        <td
          style={{
            ...cellStyles.serviceDescription,
            ...hideOnMobile.serviceDescription,
          }}
          className="hide-on-mobile"
        >
          <div className="row">
            <div className="col-md-11">{data.serviceDescription}</div>
            <div className="col-md-1"></div>
          </div>
        </td>

        <td style={cellStyles.serviceArea}>
          <div className="row">
            <div className="col-md-11">{data.serviceArea}</div>
            <div className="col-md-1"></div>
          </div>
        </td>

        <td style={cellStyles.rating}>
          <div className="row">
            <div className="col-md-11">{starRendering(data.rating)}</div>
            <div className="col-md-1"></div>
          </div>
        </td>

        <td style={cellStyles.viewMore}>
          <ViewMoreButton>View</ViewMoreButton>
        </td>

        <td style={cellStyles.quote}>
          <ViewMoreButton>Quote</ViewMoreButton>
        </td>
      </>
    );
  };

  const starRendering = (rating) => {
    if (rating == 1) {
      return;
      <p>★</p>;
    } else if (rating == 2) {
      return <p>★ ★</p>;
    } else if (rating == 3) {
      return <p>★ ★ ★</p>;
    } else if (rating == 4) {
      return <p>★ ★ ★ ★</p>;
    } else if (rating == 5) {
      return <p>★ ★ ★ ★ ★</p>;
    } else {
      return "N/A";
    }
  };

  const starFiltering = (starNumber) => {
    console.log("No. of start :", starNumber);

    if (starNumber == 1) {
      setOneStar();
    }
  };

  return (
    <>
      <div className="ser-pro-area">
        <div className="ser-pro-tittle">
          <h2>{t("matchSerPro.listOfSerPro")}</h2>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <GetQuoteButton>Request For Quotations</GetQuoteButton>
        </div>

        <div className="ser-pro-match-container row">
          <div className="side-bar-area col-lg-3">
            <React.Fragment>
              <Col>
                <div className="side-bar mt-lg-0">
                  <div className="accordion" id="accordion">
                    <div className="accordion-area">
                      <h2 className="accordion-header" id="experienceOne">
                        <SideBarButton
                          className="accordion-button"
                          onClick={(e) => {
                            e.preventDefault();
                            setIndustryToggle(!industryToggle);
                          }}
                          role="button"
                          id="IndustryCollapse"
                        >
                          Industry
                        </SideBarButton>
                      </h2>
                      <Collapse isOpen={industryToggle}>
                        <div className="accordion-body">
                          <div className="side-title">
                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked2"
                                checked={!isAccountantChecked}
                                onChange={handleAccountantOnChange}
                              />
                              <label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked2"
                              >
                                Accountant
                              </label>
                            </div>

                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked2"
                                checked={!isAuditorChecked}
                                onChange={handleAuditorOnChange}
                              />
                              <label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked2"
                              >
                                Auditor
                              </label>
                            </div>

                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked3"
                                checked={!isComSecChecked}
                                onChange={handleComSecOnChange}
                              />
                              <label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked3"
                              >
                                Company Secretary
                              </label>
                            </div>

                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked4"
                                checked={!isMarketingChecked}
                                onChange={handleMarketingOnChange}
                              />
                              <label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked4"
                              >
                                Marketing
                              </label>
                            </div>

                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked4"
                                checked={!isLegalAdvisorChecked}
                                onChange={handleLegalAdvisorOnChange}
                              />
                              <label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked4"
                              >
                                Legal Advisor
                              </label>
                            </div>

                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked4"
                                checked={!isFinAdvisorChecked}
                                onChange={handleFinAdvisorOnChange}
                              />
                              <label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked4"
                              >
                                Financial Advisor
                              </label>
                            </div>

                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked4"
                                checked={!isHrAdvisorChecked}
                                onChange={handleHrAdvisorOnChange}
                              />
                              <label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked4"
                              >
                                HR Advisor
                              </label>
                            </div>

                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked4"
                                checked={!isJobAgentChecked}
                                onChange={handleJobAgentOnChange}
                              />
                              <label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked4"
                              >
                                Job Agency
                              </label>
                            </div>

                            <div className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked4"
                                checked={!isOtherSerProChecked}
                                onChange={handleOtherSerProOnChange}
                              />
                              <label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked4"
                              >
                                Other Services
                              </label>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>

                    <div className="accordion-area mt-3">
                      <h2 className="accordion-header" id="rating">
                        <SideBarButton
                          className="accordion-button"
                          onClick={(e) => {
                            e.preventDefault();
                            setRatingToggle(!ratingToggle);
                          }}
                          role="button"
                          id="collapseExample"
                        >
                          Rating
                        </SideBarButton>
                      </h2>

                      <Collapse isOpen={ratingToggle}>
                        <div className="accordion-body">
                          <div className="side-title form-check-all">
                            <div className="form-check mt-2">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                name="datePosted"
                                value="last"
                                id="flexCheckChecked5"
                                checked={isOneStarChecked}
                                onChange={handleOneStarOnChange}
                              />
                              <Label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked5"
                              >
                                ★
                              </Label>
                            </div>
                            <div className="form-check mt-2">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                name="datePosted"
                                value="last"
                                id="flexCheckChecked6"
                                checked={isTwoStarChecked}
                                onChange={handleTwoStarOnChange}
                              />
                              <Label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked6"
                              >
                                ★ ★
                              </Label>
                            </div>
                            <div className="form-check mt-2">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                name="datePosted"
                                value="last"
                                id="flexCheckChecked7"
                                checked={isThreeStarChecked}
                                onChange={handleThreeStarOnChange}
                              />
                              <Label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked7"
                              >
                                ★ ★ ★
                              </Label>
                            </div>
                            <div className="form-check mt-2">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                name="datePosted"
                                value="last"
                                id="flexCheckChecked8"
                                checked={isFourStarChecked}
                                onChange={handleFourStarOnChange}
                              />
                              <Label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked8"
                              >
                                ★ ★ ★ ★
                              </Label>
                            </div>
                            <div className="form-check mt-2">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                name="datePosted"
                                value="last"
                                id="flexCheckChecked9"
                                checked={isFiveStarChecked}
                                onChange={handleFiveStarOnChange}
                              />
                              <Label
                                className="form-check-label ms-2 text-muted"
                                htmlFor="flexCheckChecked9"
                              >
                                ★ ★ ★ ★ ★
                              </Label>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </Col>
            </React.Fragment>
          </div>

          <div className="ser-pro-match-context col-lg-9">
            {/* Service Provider Main Context */}
            <table
              style={tableStyle}
              offset={offset}
              limit={10}
              className="my-table"
            >
              <thead>
                <tr style={headerStyle}>
                  <th
                    style={{ ...cellStyles.logo, ...hideOnMobile.logo }}
                    className="hide-on-mobile"
                  >
                    {/* Logo */}
                  </th>

                  <th style={cellStyles.firm}>
                    <div className="row">
                      {/* <div className="col-md-1"></div> */}
                      <div className="col-md-10">Company</div>
                      <div className="col-md-1"></div>
                    </div>
                  </th>

                  <th
                    style={{
                      ...cellStyles.serviceDescription,
                      ...hideOnMobile.serviceDescription,
                    }}
                    className="hide-on-mobile"
                  >
                    <div className="row">
                      <div className="col-md-11">Company Description</div>
                      {/* <div className="col-md-1"></div> */}
                    </div>
                  </th>

                  <th style={cellStyles.serviceArea}>
                    <div className="row">
                      <div className="col-md-11">Service Area</div>
                      <div className="col-md-1"></div>
                    </div>
                  </th>

                  <th style={cellStyles.rating}>
                    <div className="row">
                      <div className="col-md-12">User Ratings</div>
                      {/* <div className="col-md-1"></div> */}
                    </div>
                  </th>
                  <th style={cellStyles.viewMore}>
                    <div className="col-md-11">View More</div>
                    <div className="col-md-1"></div>
                  </th>
                  <th style={cellStyles.quote}>
                    <div className="col-md-12">Quote</div>
                    {/* <div className="col-md-1"></div> */}
                  </th>
                </tr>
              </thead>

              <tbody>
                {!accountant &&
                  !auditor &&
                  !comSec &&
                  !marketing &&
                  !legalAdvisor &&
                  !finAdvisor &&
                  !hrAdvisor &&
                  !jobAgent &&
                  !otherSerPro &&
                  serviceProviderList
                    .slice(offset, offset + limit)
                    .map((data) => (
                      <tr key={data.id} style={tableContextStyle}>
                        <ServiceProviderRow data={data} />
                      </tr>
                    ))}

                {accountant &&
                  serviceProviderList.map((data) => (
                    <tr key={data.id} style={tableContextStyle}>
                      {data.type == "accountant" && (
                        <ServiceProviderRow data={data} />
                      )}
                    </tr>
                  ))}

                {auditor &&
                  serviceProviderList.map((data) => (
                    <tr key={data.id} style={tableContextStyle}>
                      {data.type == "auditor" && (
                        <ServiceProviderRow data={data} />
                      )}
                    </tr>
                  ))}

                {comSec &&
                  serviceProviderList.map((data) => (
                    <tr key={data.id} style={tableContextStyle}>
                      {data.type == "comSec" && (
                        <ServiceProviderRow data={data} />
                      )}
                    </tr>
                  ))}

                {marketing &&
                  serviceProviderList.map((data) => (
                    <tr key={data.id} style={tableContextStyle}>
                      {data.type == "marketing" && (
                        <ServiceProviderRow data={data} />
                      )}
                    </tr>
                  ))}

                {legalAdvisor &&
                  serviceProviderList.map((data) => (
                    <tr key={data.id} style={tableContextStyle}>
                      {data.type == "legalAdvisor" && (
                        <ServiceProviderRow data={data} />
                      )}
                    </tr>
                  ))}

                {finAdvisor &&
                  serviceProviderList.map((data) => (
                    <tr key={data.id} style={tableContextStyle}>
                      {data.type == "finAdvisor" && (
                        <ServiceProviderRow data={data} />
                      )}
                    </tr>
                  ))}

                {hrAdvisor &&
                  serviceProviderList.map((data) => (
                    <tr key={data.id} style={tableContextStyle}>
                      {data.type == "hrAdvisor" && (
                        <ServiceProviderRow data={data} />
                      )}
                    </tr>
                  ))}

                {jobAgent &&
                  serviceProviderList.map((data) => (
                    <tr key={data.id} style={tableContextStyle}>
                      {data.type == "jobAgent" && (
                        <ServiceProviderRow data={data} />
                      )}
                    </tr>
                  ))}

                {otherSerPro &&
                  serviceProviderList.map((data) => (
                    <tr key={data.id} style={tableContextStyle}>
                      {data.type == "otherSerPro" && (
                        <ServiceProviderRow data={data} />
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination buttons handling  */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "25px",
          }}
        >
          <PageButton
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="page-btn"
          >
            Previous
          </PageButton>

          {pageButtons}

          <PageButton
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="page-btn"
          >
            Next
          </PageButton>
        </div>
      </div>
    </>
  );
};

const GetQuoteButton = styled(animated.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 250px;
  padding: 0 20px;
  margin: 0 10px;
  background-color: #0f1a2a;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  color: white;
  font-weight: 700;

  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 800;
  }

  &:hover {
    background-color: darkblue;
  }

  &:active {
    background-color: #e6e6e6;
  }
`;

const SideBarButton = styled(animated.button)`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 40px;
  width: 250px;
  padding: 0 20px;
  margin: 0 10px;
  background-color: #50af9b;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  font-size: 20px;
  color: white;

  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #e6e6e6;
  }
`;

const ViewMoreButton = styled(animated.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 70px;
  padding: 0 20px;
  margin: 0 10px;
  background-color: #ebeaed;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;

  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #e6e6e6;
  }
`;

const Button = styled(animated.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 55px;
  padding: 0 20px;
  margin: 0 10px;
  background-color: #ebeaed;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;

  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #e6e6e6;
  }
`;

const PageButton = styled(animated.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 100px;
  padding: 0 20px;
  margin: 0 10px;
  background-color: #ebeaed;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;

  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #e6e6e6;
  }
`;

export default SerProMatchArea;
