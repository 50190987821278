import React from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

import forumIndustryList from "../scripts/forumIndustryList";

const ForumSections = () => {
  return (
    <>
      <div className="case-study-area bg-relative pd-top-90 pd-bottom-120">
        <img
          className="animate-img-1 top_image_bounce"
          src="assets/img/banner/2.png"
          alt="img"
        />
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">DISCUSS HERE</h6>
            <h2 className="title">
              Leading The <span>Startups</span> Revolution
            </h2>
          </div>
          <div className="row">
            {forumIndustryList.map((data) => (
              <div key={data.id} className="col-lg-4 col-md-6">
                <div className="single-case-study-inner">
                  <div className="thumb">
                    <img src={data.industry_img} alt="img" />
                  </div>
                  <div className="details">
                    <h5>
                      <Link to="#">
                        {data.industry_type_en}
                      </Link>
                    </h5>
                    <a className="cat" href="#">
                      {data.no_of_comments} comments
                    </a>
                    <a className="right-arrow" href="#">
                      <FaAngleDoubleRight />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForumSections;
