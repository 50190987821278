import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Collapse,
  Input,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import jobVacancyList from "../scripts/jobVacancyList";

const StartupMatchArea = () => {
  const { t } = useTranslation();

  const [toggleFirst, setToggleFirst] = useState(true);
  const [toggleSecond, setToggleSecond] = useState(true);
  const [toggleThird, setToggleThird] = useState(true);
  const [toggleFourth, setToggleFourth] = useState(true);
  const [toggleFifth, setToggleFifth] = useState(true);
  const [value, setValue] = React.useState(50);
  //CheckBox
  const [isChecked, setIsChecked] = useState(true);
  const [isDateChecked, setIsDateChecked] = useState(true);

  const [industry, setIndustry] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [keywords, setKeywords] = useState("");

  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  const [currentPage, setCurrentPage] = useState(1);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleKeywordsChange = (event) => {
    setKeywords(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("search co-founer handling...");
    // Handle search submit
  };

  const handleDateOnChange = () => {
    setIsDateChecked(!isDateChecked);
  };

  return (
    <div className="startup-match-page">
      <div className="section-title text-center">
        <h6 className="sub-title">STARTUPS MATCHING</h6>
        <h2 className="title">
          {t("matchingService.optimizeTittle")}{" "}
          <span>{t("matchingService.smartMatching")}</span>
        </h2>
      </div>

      <div className="startup-match-container row">

        <div className="side-bar-area col-lg-3">
          <React.Fragment>
            <Col>
              <div className="side-bar mt-lg-0">
                <div className="accordion" id="accordion">
                  <div className="accordion-area">
                    <h2 className="accordion-header" id="locationOne">
                      <Button
                        className="accordion-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setToggleFirst(!toggleFirst);
                        }}
                        role="button"
                        id="collapseExample"
                      >
                        Company's Name
                      </Button>
                    </h2>

                    <Collapse isOpen={toggleFirst}>
                      <div className="accordion-body">
                        <div className="side-title">
                          <div className="mb-3">
                            <form className="position-relative">
                              <Input
                                className="form-control"
                                type="search"
                                placeholder="Search..."
                              />
                              <button
                                className="bg-transparent border-0 position-absolute top-50 end-0 translate-middle-y me-2"
                                type="submit"
                              >
                                <span className="mdi mdi-magnify text-muted"></span>
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-area mt-3">
                    <h2 className="accordion-header" id="experienceOne">
                      <Button
                        className="accordion-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setToggleSecond(!toggleSecond);
                        }}
                        role="button"
                        id="collapseExample"
                      >
                        Work experience
                      </Button>
                    </h2>
                    <Collapse isOpen={toggleSecond}>
                      <div className="accordion-body">
                        <div className="side-title">
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked1"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked1"
                            >
                              No experience
                            </label>
                          </div>
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked2"
                              checked={isChecked}
                              onChange={handleOnChange}
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked2"
                            >
                              0-3 years
                            </label>
                          </div>
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked3"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked3"
                            >
                              3-6 years
                            </label>
                          </div>
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked4"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked4"
                            >
                              More than 6 years
                            </label>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-area mt-3">
                    <h2 className="accordion-header" id="jobType">
                      <Button
                        className="accordion-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setToggleThird(!toggleThird);
                        }}
                        role="button"
                        id="collapseExample"
                      >
                        Type of employment
                      </Button>
                    </h2>
                    <Collapse isOpen={toggleThird}>
                      <div className="accordion-body">
                        <div className="side-title">
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault6"
                              defaultChecked
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexRadioDefault6"
                            >
                              Freelance
                            </label>
                          </div>

                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexRadioDefault2"
                            >
                              Full Time
                            </label>
                          </div>

                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault3"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexRadioDefault3"
                            >
                              Internship
                            </label>
                          </div>

                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault4"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexRadioDefault4"
                            >
                              Part Time
                            </label>
                          </div>

                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault4"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexRadioDefault4"
                            >
                              Contract
                            </label>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-area mt-3">
                    <h2 className="accordion-header" id="datePosted">
                      <Button
                        className="accordion-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setToggleFourth(!toggleFourth);
                        }}
                        role="button"
                        id="collapseExample"
                      >
                        Date Posted
                      </Button>
                    </h2>
                    <Collapse isOpen={toggleFourth}>
                      <div className="accordion-body">
                        <div className="side-title form-check-all">
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="checkAll"
                              value=""
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="checkAll"
                            >
                              All
                            </Label>
                          </div>
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="datePosted"
                              value="last"
                              id="flexCheckChecked5"
                              checked={isDateChecked}
                              onChange={handleDateOnChange}
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked5"
                            >
                              Last Hour
                            </Label>
                          </div>
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="datePosted"
                              value="last"
                              id="flexCheckChecked6"
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked6"
                            >
                              Last 24 hours
                            </Label>
                          </div>
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="datePosted"
                              value="last"
                              id="flexCheckChecked7"
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked7"
                            >
                              Last 7 days
                            </Label>
                          </div>
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="datePosted"
                              value="last"
                              id="flexCheckChecked8"
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked8"
                            >
                              Last 14 days
                            </Label>
                          </div>
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="datePosted"
                              value="last"
                              id="flexCheckChecked9"
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked9"
                            >
                              Last 30 days
                            </Label>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </Col>
          </React.Fragment>
        </div>

        <div className="main-startup-context col-lg-9">
          <div className="filter-bar-area">

            <form className="search-box" onSubmit={handleSubmit}>
              <div className="search-box__form-group">
                <label htmlFor="industry">
                  {t("matchingService.industry")}
                </label>
                <select
                  id="industry"
                  name="industry"
                  onChange={handleIndustryChange}
                >
                  <option value="">
                    {t("matchingService.industrySelection")}
                  </option>
                  <option value="technology">
                    {t("matchingService.industry_technology")}
                  </option>
                  <option value="food">
                    {t("matchingService.industry_food")}
                  </option>
                  <option value="retail">
                    {t("matchingService.industry_retail")}
                  </option>
                  <option value="education">
                    {t("matchingService.industry_education")}
                  </option>
                  <option value="service">
                    {t("matchingService.industry_service")}
                  </option>
                  <option value="cultural">
                    {t("matchingService.industry_cultural")}
                  </option>
                  <option value="environment">
                    {t("matchingService.industry_environment")}
                  </option>
                </select>
              </div>

              <div className="search-box__form-group">
                <label htmlFor="business-type">Location:</label>
                <select
                  id="business-type"
                  name="business-type"
                  onChange={handleLocationChange}
                >
                  <option value="">Select location</option>
                  <option value="central">Central and Western</option>
                  <option value="eastern">Eastern</option>
                  <option value="southern">Southern</option>
                  <option value="wan-chai">Wan Chai</option>
                  <option value="kowloon-city">Kowloon City</option>
                  <option value="kwun-tong">Kwun Tong</option>
                  <option value="sham-shui-po">Sham Shui Po</option>
                  <option value="wong-tai-sin">Wong Tai Sin</option>
                  <option value="yau-tsim-mong">Yau Tsim Mong</option>
                  <option value="kwai-tsing">Kwai Tsing</option>
                  <option value="north">North</option>
                  <option value="sai-kung">Sai Kung</option>
                  <option value="shatin">Sha Tin</option>
                  <option value="tai-po">Tai Po</option>
                  <option value="tsuen-wan">Tsuen Wan</option>
                  <option value="tuen-mun">Tuen Mun</option>
                  <option value="yuen-long">Yuen Long</option>
                </select>
              </div>

              <div className="search-box__form-group">
                <label htmlFor="keywords">Job Title / Key Word :</label>
                <input
                  id="keywords"
                  name="keywords"
                  type="text"
                  onChange={handleKeywordsChange}
                />
              </div>

              <Link to="#">
                <button type="submit">{t("matchingService.search")}</button>
              </Link>
            </form>
            
          </div>

          <div className="all-startup-details">
            <React.Fragment>
              <div>
                {jobVacancyList.map((jobVacancyListDetails, key) => (
                  <div
                    key={key}
                    className={
                      jobVacancyListDetails.addclassNameBookmark === true
                        ? "job-box bookmark-post card mt-4"
                        : "job-box card mt-4"
                    }
                  >
                    <div className="bookmark-label text-center">
                      <Link to="#" className="align-middle text-white">
                        <i className="mdi mdi-star"></i>
                      </Link>
                    </div>
                    <div className="p-4">
                      <Row className="align-items-center">
                        <Col md={2}>
                          <div className="text-center mb-4 mb-md-0">
                            <Link to="/companydetails">
                              <img
                                src={jobVacancyListDetails.companyImg}
                                alt=""
                                className="img-fluid rounded-3"
                                style={{
                                  width: "50%",
                                  height: "50%",
                                }}
                              />
                            </Link>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-2 mb-md-0">
                            <h5 className="fs-18 mb-0">
                              <Link to="/jobdetails" className="text-dark">
                                {jobVacancyListDetails.jobDescription}
                              </Link>
                            </h5>
                            <p className="text-muted fs-14 mb-0">
                              {jobVacancyListDetails.companyName}
                            </p>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="d-flex mb-2">
                            <div className="flex-shrink-0">
                              <i className="mdi mdi-map-marker text-primary me-1"></i>
                            </div>
                            <p className="text-muted mb-0">
                              {jobVacancyListDetails.location}
                            </p>
                          </div>
                        </Col>

                        <Col md={2}>
                          <div className="d-flex mb-0">
                            <div className="flex-shrink-0">
                              <i className="uil uil-clock-three text-primary me-1"></i>
                            </div>
                            <p className="text-muted mb-0">
                              {" "}
                              {jobVacancyListDetails.jobPostTime}
                            </p>
                          </div>
                        </Col>

                        <Col md={2}>
                          <div>
                            <span
                              className={
                                jobVacancyListDetails.fullTime === true
                                  ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                                  : jobVacancyListDetails.partTime === true
                                  ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                                  : jobVacancyListDetails.freeLance === true
                                  ? "badge bg-primary-subtle text-primary fs-13 mt-1 mx-1"
                                  : jobVacancyListDetails.internship === true
                                  ? "badge bg-blue-subtle text-blue fs-13 mt-1"
                                  : ""
                              }
                            >
                              {jobVacancyListDetails.timing}
                            </span>
                            {(jobVacancyListDetails.badges || []).map(
                              (badgeInner, key) => (
                                <span
                                  className={`badge ${badgeInner.badgeclassName} fs-13 mt-1`}
                                  key={key}
                                >
                                  {badgeInner.badgeName}
                                </span>
                              )
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="p-3 bg-light">
                      <Row className="justify-content-between">
                        <Col md={4}>
                          <div>
                            <p className="text-muted mb-0">
                              <span className="text-dark">Experience :</span>
                              {jobVacancyListDetails.experience}
                            </p>
                          </div>
                        </Col>
                        <Col lg={2} md={3}>
                          <div>
                            <Link
                              to="#applyNow"
                              onClick={openModal}
                              className="primary-link"
                            >
                              Apply Now{" "}
                              <i className="mdi mdi-chevron-double-right"></i>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
                <div
                  className="modal fade"
                  id="applyNow"
                  tabIndex="-1"
                  aria-labelledby="applyNow"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <Modal isOpen={modal} toggle={openModal} centered>
                      <ModalBody className="modal-body p-5">
                        <div className="text-center mb-4">
                          <h5 className="modal-title" id="staticBackdropLabel">
                            Apply For This Job
                          </h5>
                        </div>
                        <div className="position-absolute end-0 top-0 p-3">
                          <button
                            type="button"
                            onClick={openModal}
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="mb-3">
                          <Label for="nameControlInput" className="form-label">
                            Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="nameControlInput"
                            placeholder="Enter your name"
                          />
                        </div>
                        <div className="mb-3">
                          <Label
                            for="emailControlInput2"
                            className="form-label"
                          >
                            Email Address
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="emailControlInput2"
                            placeholder="Enter your email"
                          />
                        </div>
                        <div className="mb-3">
                          <Label
                            for="messageControlTextarea"
                            className="form-label"
                          >
                            Message
                          </Label>
                          <textarea
                            className="form-control"
                            id="messageControlTextarea"
                            rows="4"
                            placeholder="Enter your message"
                          ></textarea>
                        </div>
                        <div className="mb-4">
                          <Label className="form-label" for="inputGroupFile01">
                            Resume Upload
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="inputGroupFile01"
                          />
                        </div>
                        <button type="submit" className="btn btn-primary w-100">
                          Send Application
                        </button>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupMatchArea;
