import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import UserMatchingArea from "../components/UserMatchingArea";
import ServiceBanner from "../components/ServiceBanner";

const Service = () => {
  return (
    <div>
      {/* Navigation Bar */}
      <NavBar />

      {/* Serivce Banner Area */}
      <ServiceBanner />

      {/* User Matching Area */}
      <UserMatchingArea />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Service;
