import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Collapse,
  Input,
  Label,
  CardBody,
  Modal,
  ModalBody,
} from "reactstrap";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import adviserList from "../scripts/adviserList";

const AdviserMatchArea = () => {
  const { t } = useTranslation();

  const [toggleFirst, setToggleFirst] = useState(true);
  const [toggleSecond, setToggleSecond] = useState(true);
  const [toggleThird, setToggleThird] = useState(true);
  const [toggleFourth, setToggleFourth] = useState(true);
  const [toggleFifth, setToggleFifth] = useState(true);
  const [value, setValue] = useState(300);
  const [isRateChecked, setIsRateChecked] = useState(true);

  const [isChecked, setIsChecked] = useState(true); //CheckBox

  const [industry, setIndustry] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [keywords, setKeywords] = useState("");
  

  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };


  const handleRateOnChange = () => {
    setIsRateChecked(!isRateChecked);
  };

  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleKeywordsChange = (event) => {
    setKeywords(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("search co-founer handling...");
    // Handle search submit
  };

  return (
    <div className="adviser-match-page">
      <div className="section-title text-center">
        <h6 className="sub-title">ADVISERS MATCHING</h6>
        <h2 className="title">
          {t("matchingService.optimizeTittle")}{" "}
          <span>{t("matchingService.smartMatching")}</span>
        </h2>
      </div>

      <div className="adviser-match-container row">
        <div className="side-bar-area col-lg-3">
          <React.Fragment>
            <Col>
              <div className="side-bar mt-lg-0">
                <div className="accordion" id="accordion">
                  <div className="accordion-area">
                    <h2 className="accordion-header" id="locationOne">
                      <Button
                        className="accordion-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setToggleFirst(!toggleFirst);
                        }}
                        role="button"
                        id="collapseExample"
                      >
                        Price
                      </Button>
                    </h2>

                    <Collapse isOpen={toggleFirst}>
                      <div className="accordion-body">
                        <div className="side-title">
                          <div className="area-range slidecontainer">
                            <div className="form-label mb-4">
                              Hourly rate: HK$ {value} per hour
                              <span
                                className="example-val mt-2"
                                id="slider1-span"
                              ></span>
                            </div>
                            <input
                              type="range"
                              min="1"
                              max="1000"
                              value={value}
                              onChange={({ target: { value } }) =>
                                setValue(value)
                              }
                              className={`slider ${
                                value > 50 ? "slider-50" : "slider-0"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-area mt-3">
                    <h2 className="accordion-header" id="experienceOne">
                      <Button
                        className="accordion-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setToggleSecond(!toggleSecond);
                        }}
                        role="button"
                        id="collapseExample"
                      >
                        Relevant Experience
                      </Button>
                    </h2>
                    <Collapse isOpen={toggleSecond}>
                      <div className="accordion-body">
                        <div className="side-title">
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked2"
                              checked={isChecked}
                              onChange={handleOnChange}
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked2"
                            >
                              0-2 years
                            </label>
                          </div>

                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked2"
                              checked={isChecked}
                              onChange={handleOnChange}
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked2"
                            >
                              2-4 years
                            </label>
                          </div>

                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked3"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked3"
                            >
                              4-6 years
                            </label>
                          </div>
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked4"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked4"
                            >
                              More than 6 years
                            </label>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-area mt-3">
                    <h2 className="accordion-header" id="jobType">
                      <Button
                        className="accordion-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setToggleThird(!toggleThird);
                        }}
                        role="button"
                        id="collapseExample"
                      >
                        Type of employment
                      </Button>
                    </h2>
                    <Collapse isOpen={toggleThird}>
                      <div className="accordion-body">
                        <div className="side-title">
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault6"
                              defaultChecked
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexRadioDefault6"
                            >
                              Freelance
                            </label>
                          </div>
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexRadioDefault2"
                            >
                              Full Time
                            </label>
                          </div>

                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault4"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexRadioDefault4"
                            >
                              Part Time
                            </label>
                          </div>

                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault4"
                            />
                            <label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexRadioDefault4"
                            >
                              Contract / By Project
                            </label>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-area mt-3">
                    <h2 className="accordion-header" id="datePosted">
                      <Button
                        className="accordion-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setToggleFourth(!toggleFourth);
                        }}
                        role="button"
                        id="collapseExample"
                      >
                        Rating
                      </Button>
                    </h2>

                    <Collapse isOpen={toggleFourth}>
                      <div className="accordion-body">
                        <div className="side-title form-check-all">
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="datePosted"
                              value="last"
                              id="flexCheckChecked5"
                              checked={isRateChecked}
                              onChange={handleRateOnChange}
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked5"
                            >
                              ★
                            </Label>
                          </div>
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="datePosted"
                              value="last"
                              id="flexCheckChecked6"
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked6"
                            >
                              ★ ★
                            </Label>
                          </div>
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="datePosted"
                              value="last"
                              id="flexCheckChecked7"
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked7"
                            >
                              ★ ★ ★
                            </Label>
                          </div>
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="datePosted"
                              value="last"
                              id="flexCheckChecked8"
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked8"
                            >
                              ★ ★ ★ ★
                            </Label>
                          </div>
                          <div className="form-check mt-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="datePosted"
                              value="last"
                              id="flexCheckChecked9"
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor="flexCheckChecked9"
                            >
                              ★ ★ ★ ★ ★
                            </Label>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </Col>
          </React.Fragment>
        </div>

        <div className="main-adviser-context col-lg-9">
          <div className="filter-bar-area ">
            <form className="search-box" onSubmit={handleSubmit}>
              <div className="search-box__form-group">
                <label htmlFor="industry">
                  {t("matchingService.industry")}
                </label>
                <select
                  id="industry"
                  name="industry"
                  onChange={handleIndustryChange}
                >
                  <option value="">
                    {t("matchingService.industrySelection")}
                  </option>
                  <option value="technology">
                    {t("matchingService.industry_technology")}
                  </option>
                  <option value="food">
                    {t("matchingService.industry_food")}
                  </option>
                  <option value="retail">
                    {t("matchingService.industry_retail")}
                  </option>
                  <option value="education">
                    {t("matchingService.industry_education")}
                  </option>
                  <option value="service">
                    {t("matchingService.industry_service")}
                  </option>
                  <option value="cultural">
                    {t("matchingService.industry_cultural")}
                  </option>
                  <option value="environment">
                    {t("matchingService.industry_environment")}
                  </option>
                </select>
              </div>

              <div className="search-box__form-group">
                <label htmlFor="business-type">Location:</label>
                <select
                  id="business-type"
                  name="business-type"
                  onChange={handleLocationChange}
                >
                  <option value="">Select location</option>
                  <option value="central">Central and Western</option>
                  <option value="eastern">Eastern</option>
                  <option value="southern">Southern</option>
                  <option value="wan-chai">Wan Chai</option>
                  <option value="kowloon-city">Kowloon City</option>
                  <option value="kwun-tong">Kwun Tong</option>
                  <option value="sham-shui-po">Sham Shui Po</option>
                  <option value="wong-tai-sin">Wong Tai Sin</option>
                  <option value="yau-tsim-mong">Yau Tsim Mong</option>
                  <option value="kwai-tsing">Kwai Tsing</option>
                  <option value="north">North</option>
                  <option value="sai-kung">Sai Kung</option>
                  <option value="shatin">Sha Tin</option>
                  <option value="tai-po">Tai Po</option>
                  <option value="tsuen-wan">Tsuen Wan</option>
                  <option value="tuen-mun">Tuen Mun</option>
                  <option value="yuen-long">Yuen Long</option>
                </select>
              </div>

              <div className="search-box__form-group">
                <label htmlFor="keywords">Mentor / Adviser's Name :</label>
                <input
                  id="keywords"
                  name="keywords"
                  type="text"
                  placeholder="i.e. Peter Chu"
                  onChange={handleKeywordsChange}
                />
              </div>

              <Link to="#">
                <button type="submit">{t("matchingService.search")}</button>
              </Link>
            </form>
          </div>

          <div className="main-adviser-details">
            <Row>
              {adviserList.map((adviserDetails, key) => (
                <Col lg={4} md={6} key={key}>
                  <div
                    className={
                      adviserDetails.addclassNameBookmark === true
                        ? "candidate-grid-box bookmark-post card mt-4"
                        : "candidate-grid-box card mt-4"
                    }
                  >
                    <CardBody className="p-4">
                      <div className="d-flex mb-4">
                        <div className="flex-shrink-0 position-relative">
                          <img
                            src={adviserDetails.userImg}
                            alt=""
                            className="avatar-md rounded"
                            style={{
                              width: "100px",
                              heigh: "100px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>

                        <div className="ms-3">
                          <Link to="" className="primary-link">
                            <h5 className="fs-17">
                              {adviserDetails.candidateName}
                            </h5>
                          </Link>
                          <span className="badge bg-info-subtle text-info fs-13">
                            {adviserDetails.salary}
                          </span>

                          <p
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            {adviserDetails.industry}
                          </p>
                        </div>
                      </div>

                      <div className="border rounded mb-4">
                        <div className="row g-0">
                          <Col lg={6}>
                            <div className="border-end px-3 py-2">
                              <p className="text-muted mb-0">
                                Exp. : {adviserDetails.experience}
                              </p>
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="px-3 py-2">
                              <p className="text-muted mb-0">
                                {adviserDetails.jobType}
                              </p>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <p className="text-muted">{adviserDetails.briefIntro}</p>
                      <div className="mt-3">
                        <Link
                          to="#hireNow"
                          onClick={openModal}
                          data-bs-toggle="modal"
                          className="btn btn-primary btn-hover w-100 mt-2"
                        >
                          <i className="mdi mdi-account-check"></i> Connect Now
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-soft-primary btn-hover w-100 mt-2"
                        >
                          <i className="mdi mdi-eye"></i> View Profile
                        </Link>
                      </div>
                    </CardBody>
                  </div>
                </Col>
              ))}
            </Row>
            <div
              className="modal fade"
              id="hireNow"
              tabIndex="-1"
              aria-labelledby="hireNow"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <Modal isOpen={modal} toggle={openModal} centered>
                  <ModalBody className="p-5">
                    <div className="text-center mb-4">
                      <h5 className="modal-title" id="staticBackdropLabel">
                        Hire Now
                      </h5>
                    </div>
                    <div className="position-absolute end-0 top-0 p-3">
                      <button
                        type="button"
                        onClick={openModal}
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="mb-3">
                      <Label for="namrFormControlInput" className="form-label">
                        Company Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="namrFormControlInput"
                        placeholder="Enter your company name"
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="emailFormControlInput" className="form-label">
                        Email Address
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="emailFormControlInput"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="mb-4">
                      <Label
                        for="messageFormControlTextarea"
                        className="form-label"
                      >
                        Message
                      </Label>
                      <textarea
                        className="form-control"
                        id="messageFormControlTextarea"
                        rows="4"
                        placeholder="Enter your message"
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary w-100">
                      Send Message
                    </button>
                  </ModalBody>
                </Modal>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdviserMatchArea;
