const forumIndustryList = [
{
    industry_type_en: "Innovation and technology",
    industry_type_ch: "創新科技",
    industry_img: "assets/img/forum/innovation_and_technology.png",
    no_of_comments: "254"
},
{
    industry_type_en: "Food and beverage",
    industry_type_ch: "飲食",
    industry_img: "assets/img/forum/food_and_beverage.png",
    no_of_comments: "153"
},
{
    industry_type_en: "Retail sales",
    industry_type_ch: "零售",
    industry_img: "assets/img/forum/retail_sales.png",
    no_of_comments: "86"
},
{
    industry_type_en: "Education and tutoring",
    industry_type_ch: "教育",
    industry_img: "assets/img/forum/education_and_tutoring.png",
    no_of_comments: "63"
},
{
    industry_type_en: "Services",
    industry_type_ch: "服務",
    industry_img: "assets/img/forum/service_industry.png",
    no_of_comments: "59"
},
{
    industry_type_en: "Cultural and creative industries",
    industry_type_ch: "文化創作",
    industry_img: "assets/img/forum/cultural_and_creative_industries.png",
    no_of_comments: "43"
},
{
    industry_type_en: "Environmental industry",
    industry_type_ch: "環保",
    industry_img: "assets/img/forum/environmental_industry.png",
    no_of_comments: "35"
},
]

export default forumIndustryList;