let userList = [
  {
    id: 1,
    user_name: "Ken Wong",
    user_icon: "assets/img/smart-matching/user-icon/user-ken-wong.png",
    user_skill: "Accounting, Finance, Funding Operation",
    startup_industry: "Technology",
    company_logo: "assets/img/smart-matching/company-logo/com-logo-A.png",
    company_description: "Entrepreneur Matching Platform",
    },
    {
      id: 2,
      user_name: "Lisa Chen",
      user_icon: "assets/img/smart-matching/user-icon/user-lisa-chen.png",
      user_skill: "Marketing, Sales, Branding",
      startup_industry: "E-commerce",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-B.png",
      company_description: "Online Retail Marketplace",
    },
    {
      id: 3,
      user_name: "David Kim",
      user_icon: "assets/img/smart-matching/user-icon/user-david-kim.png",
      user_skill: "Software Development, Project Management",
      startup_industry: "Healthcare",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-C.png",
      company_description: "Telemedicine Solution Provider",
    },
    {
      id: 4,
      user_name: "Sarah Lee",
      user_icon: "assets/img/smart-matching/user-icon/user-sarah-lee.png",
      user_skill: "Product Design, User Experience, UI/UX",
      startup_industry: "Education",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-D.png",
      company_description: "EdTech Platform",
    },
    {
      id: 5,
      user_name: "Kevin Park",
      user_icon: "assets/img/smart-matching/user-icon/user-kevin-park.png",
      user_skill: "Operations, Supply Chain, Logistics",
      startup_industry: "Manufacturing",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-E.png",
      company_description: "Sustainable Packaging Solution Provider",
    },
    {
      id: 6,
      user_name: "Emily Kim",
      user_icon: "assets/img/smart-matching/user-icon/user-emily-kim.png",
      user_skill: "Data Analysis, Business Intelligence",
      startup_industry: "Finance",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-F.png",
      company_description: "Financial Analytics Platform",
    },
    {
      id: 7,
      user_name: "Richard Lee",
      user_icon: "assets/img/smart-matching/user-icon/user-richard-lee.png",
      user_skill: "Legal, Compliance, Risk Management",
      startup_industry: "Real Estate",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-G.png",
      company_description: "Property Management Platform",
    },
    {
      id: 8,
      user_name: "Amy Chang",
      user_icon: "assets/img/smart-matching/user-icon/user-amy-chang.png",
      user_skill: "Human Resources, Talent Management",
      startup_industry: "Technology",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-H.png",
      company_description: "HR Tech Provider",
    },
    {
      id: 9,
      user_name: "Steven Wong",
      user_icon: "assets/img/smart-matching/user-icon/user-steven-wong.png",
      user_skill: "Consulting, Strategy, Business Development",
      startup_industry: "Consulting",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-I.png",
      company_description: "Management Consulting Firm",
    },
    {
      id: 10,
      user_name: "Jenny Lee",
      user_icon: "assets/img/smart-matching/user-icon/user-jenny-lee.png",
      user_skill: "Public Relations, Communications",
      startup_industry: "Media",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-J.png",
      company_description: "Digital Media Agency",
    },
    {
      id: 11,
      user_name: "Michael Park",
      user_icon: "assets/img/smart-matching/user-icon/user-michael-park.png",
      user_skill: "Engineering, Product Development",
      startup_industry: "Aerospace",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-K.png",
      company_description: "Aircraft Manufacturer",
    },
    {
      id: 12,
      user_name: "Cindy Cheung",
      user_icon: "assets/img/smart-matching/user-icon/user-cindy-cheung.png",
      user_skill: "Design, Branding, Marketing",
      startup_industry: "Fashion",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-L.png",
      company_description: "Fashion E-commerce Platform",
    },
    {
      id: 13,
      user_name: "Alex Lee",
      user_icon: "assets/img/smart-matching/user-icon/user-alex-lee.png",
      user_skill: "Sales, Business Development, Customer Success",
      startup_industry: "SaaS",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-M.png",
      company_description: "Cloud-based Project Management Software",
      },
    {
      id: 14,
      user_name: "Kelvin Yu",
      user_icon: "assets/img/smart-matching/user-icon/user-kelvin-yu.png",
      user_skill: "Digital Marketing, SEO, Social Media",
      startup_industry: "Marketing",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-N.png",
      company_description: "Marketing Agency",
    },
    {
      id: 15,
      user_name: "Peter Lee",
      user_icon: "assets/img/smart-matching/user-icon/user-peter-lee.png",
      user_skill: "Product Management, Business Analysis",
      startup_industry: "Technology",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-O.png",
      company_description: "Enterprise Software Provider",
    },
    {
      id: 16,
      user_name: "Karen Park",
      user_icon: "assets/img/smart-matching/user-icon/user-karen-park.png",
      user_skill: "Project Management, Operations",
      startup_industry: "Logistics",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-P.png",
      company_description: "Freight Forwarding and Logistics Provider",
    },
    {
      id: 17,
      user_name: "John Tsang",
      user_icon: "assets/img/smart-matching/user-icon/user-john-tsang.png",
      user_skill: "Data Science, Machine Learning, AI",
      startup_industry: "Technology",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-Q.png",
      company_description: "AI and Analytics Platform",
    },
    {
      id: 18,
      user_name: "Jessica Chan",
      user_icon: "assets/img/smart-matching/user-icon/user-jessica-chan.png",
      user_skill: "HR, Talent Acquisition, Employer Branding",
      startup_industry: "HR Tech",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-R.png",
      company_description: "Talent Management Software",
    },
    {
      id: 19,
      user_name: "Eric Lui",
      user_icon: "assets/img/smart-matching/user-icon/user-eric-lui.png",
      user_skill: "Business Development, Sales, Partnerships",
      startup_industry: "Technology",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-S.png",
      company_description: "Cloud Services Provider",
    },
    {
      id: 20,
      user_name: "Samantha Park",
      user_icon: "assets/img/smart-matching/user-icon/user-samantha-park.png",
      user_skill: "UX Design, Product Design",
      startup_industry: "E-commerce",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-T.png",
      company_description: "Online Marketplace",
    },
    {
      id: 21,
      user_name: "Daniel Wong",
      user_icon: "assets/img/smart-matching/user-icon/user-daniel-wong.png",
      user_skill: "Finance, Accounting, Tax",
      startup_industry: "Finance",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-U.png",
      company_description: "Financial Planning and Analysis Provider",
    },
    {
      id: 22,
      user_name: "Olivia Lee",
      user_icon: "assets/img/smart-matching/user-icon/user-olivia-lee.png",
      user_skill: "Marketing, Branding, Communications",
      startup_industry: "Healthcare",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-V.png",
      company_description: "Healthcare Marketing Agency",
    },
    {
      id: 23,
      user_name: "Andrew Cheung",
      user_icon: "assets/img/smart-matching/user-icon/user-andrew-cheung.png",
      user_skill: "Product Management, UX Design",
      startup_industry: "Technology",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-W.png",
      company_description: "Product Development Platform",
    },
    {
      id: 24,
      user_name: "Hannah Chu",
      user_icon: "assets/img/smart-matching/user-icon/user-hannah-chu.png",
      user_skill: "Sales, Business Development, Partnerships",
      startup_industry: "E-commerce",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-X.png",
      company_description: "Online Retailer",
    },
    {
      id: 25,
      user_name: "Robert Lee",
      user_icon: "assets/img/smart-matching/user-icon/user-robert-lee.png",
      user_skill: "Software Engineering, DevOps",
      startup_industry: "Technology",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-Y.png",
      company_description: "Cloud Infrastructure Provider",
    },
    {
      id: 26,
      user_name: "Julia Wong",
      user_icon: "assets/img/smart-matching/user-icon/user-julia-wong.png",
      user_skill: "Graphic Design, Art Direction",
      startup_industry: "Advertising",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-Z.png",
        company_description: "Creative Agency",
    },
    
    {
      id: 27,
      user_name: "George Park",
      user_icon: "assets/img/smart-matching/user-icon/user-george-park.png",
      user_skill: "Marketing, Sales, Customer Success",
      startup_industry: "SaaS",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-AA.png",
        company_description: "Cloud-based Sales Enablement Platform",
    },
    {
      id: 28,
      user_name: "Sophie Lee",
      user_icon: "assets/img/smart-matching/user-icon/user-sophie-lee.png",
      user_skill: "Product Management, UX Design",
      startup_industry: "Technology",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-BB.png",
        company_description: "Product Development Platform",
    },
    {
      id: 29,
      user_name: "David Lui",
      user_icon: "assets/img/smart-matching/user-icon/user-david-lui.png",
      user_skill: "Finance, Accounting, Tax",
      startup_industry: "Finance",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-CC.png",
        company_description: "Financial Planning and Analysis Provider",
    },
    {
      id: 30,
      user_name: "Emily Wong",
      user_icon: "assets/img/smart-matching/user-icon/user-emily-wong.png",
      user_skill: "Marketing, Content Creation, Social Media",
      startup_industry: "E-commerce",
      company_logo: "assets/img/smart-matching/company-logo/com-logo-DD.png",
        company_description: "Online Retailer",
    }
]             

export default userList;            