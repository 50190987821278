import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";


import ForumSections from "../components/ForumSections";

const Forum = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Forum"} />

      {/* Forum Sections */}
      <ForumSections />


      {/* Footer */}
      <Footer />
    </>
  );
};

export default Forum;
