import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import HomeBanner from "../components/HomeBanner";
import AboutArea from "../components/AboutArea";
import CounterArea from "../components/CounterArea";
import SignUpProcess from "../components/SignUpProcess";
import MobileDemoArea from "../components/MobileDemoArea";

const Home = () => {
  return (
    <div>
      <NavBar />

      <HomeBanner />

      <AboutArea />

      <CounterArea />

      <SignUpProcess />

      <MobileDemoArea />

      <Footer />
    </div>
  );
};

export default Home;
