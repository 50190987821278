import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import SerProMatchArea from "../components/SerProMatchArea";
import SerProMatchBanner from "../components/SerProMatchBanner";

const ServiceProviderMatching = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768); // set isMobile to true if viewport is less than 768px wide
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // set isMobile on initial render
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {/* Navigation Bar */}
      <NavBar />

      {/* Service Provider Matching Banner */}
      {!isMobile && <SerProMatchBanner />}

      {/* Service Provider Matching Area */}
      <SerProMatchArea />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ServiceProviderMatching;
