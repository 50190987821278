let jobVacancyList = [
  {
    id: 1,
    companyImg: "assets/img/smart-matching/company-logo/com-logo-J.png",
    jobDescription: "Product Director",
    companyName: "Creative Agency Limited",
    location: "Kwun Tong",
    jobPostTime: "3 min ago",
    fullTime: true,
    timing: "Full Time",
    addclassNameBookmark: false,
    badges: [],
    experience: "2 - 3 years",
  },
  {
    id: 2,
    companyImg: "assets/img/smart-matching/company-logo/com-logo-L.png",
    jobDescription: "Digital Marketing Manager",
    companyName: "Bright Technology Ltd",
    location: "Kowloon City",
    jobPostTime: "15 min ago",
    fullTime: true,
    timing: "Full Time",
    catogary: "Recent Jobs",
    addclassNameBookmark: true,
    badges: [
      {
        id: 1,
        badgeclassName: "bg-warning-subtle text-warning",
        badgeName: "Urgent",
      },
      {
        id: 2,
        badgeclassName: "bg-primary-subtle text-primary",
        badgeName: "Freelance",
      },
    ],
    experience: "4+ years",
  },
  {
    id: 3,
    companyImg: "assets/img/smart-matching/company-logo/com-logo-M.png",
    jobDescription: "Product Director",
    companyName: "Mavel Cloud Limited",
    location: "Yau Tsim Mong",
    jobPostTime: "37 min ago",
    fullTime: true,
    timing: "Full Time",
    addclassNameBookmark: false,
    badges: [],
    experience: "2 - 3 years",
  },
  {
    id: 4,
    companyImg: "assets/img/smart-matching/company-logo/com-logo-P.png",
    jobDescription: "Product Director",
    companyName: "Smart Tech Freight Limited",
    location: "Tsuen Wan",
    jobPostTime: "50 min ago",
    freeLance: true,
    timing: "Freelance",
    addclassNameBookmark: false,
    badges: [],
    experience: "2 - 3 years",
  },
  {
    id: 5,
    companyImg: "assets/img/smart-matching/company-logo/com-logo-R.png",
    jobDescription: "Product Director",
    companyName: "Good Talent Agency",
    location: "Kowloon City",
    jobPostTime: "1 month ago",
    partTime: true,
    timing: "Part Time",
    addclassNameBookmark: true,
    badges: [],
    experience: "2 - 3 years",
  },
  {
    id: 6,
    companyImg: "assets/img/smart-matching/company-logo/com-logo-AA.png",
    jobDescription: "Product Director",
    companyName: "Wonderful Cloud Solutions Limited",
    location: "Tai Po",
    jobPostTime: "2 month ago",
    freeLance: true,
    timing: "Freelance",
    addclassNameBookmark: false,
    badges: [
      {
        id: 1,
        badgeclassName: "bg-warning-subtle text-warning",
        badgeName: "Urgent",
      },
    ],
    experience: "2-3 years",
  },
  {
    id: 7,
    companyImg: "assets/img/smart-matching/company-logo/com-logo-CC.png",
    jobDescription: "Product Director",
    companyName: "Fance FinTech Limited",
    location: "Kwai Tsing",
    jobPostTime: "2 month ago",
    partTime: true,
    timing: "Part Time",
    addclassNameBookmark: false,
    badges: [
      {
        id: 1,
        badgeclassName: "bg-warning-subtle text-warning",
        badgeName: "Urgent",
      },
    ],
    experience: "2-3 years",
  },
  {
    id: 8,
    companyImg: "assets/img/smart-matching/company-logo/com-logo-C.png",
    jobDescription: "Product Director",
    companyName: "Telemedicine Solution Limited",
    location: "Yau Tsim Mong",
    jobPostTime: "3 month ago",
    internship: true,
    timing: "Internship",
    addclassNameBookmark: false,
    badges: [
      {
        id: 1,
        badgeclassName: "bg-warning-subtle text-warning",
        badgeName: "Private",
      },
    ],
    experience: "2-3 years",
  },
];

export default jobVacancyList;
