import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import StartupMatchArea from "../components/StartupMatchArea";

const Startups = () => {
  return (
    <div>
      <NavBar />

      <StartupMatchArea />

      <Footer />
    </div>
  );
};

export default Startups;
