import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import ServiceAreaGroup from "../components/ServiceAreaGroup";

const Marketplace = () => {
  return (
    <div>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Marketplace"} />

      {/* Service Area */}
      <ServiceAreaGroup />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Marketplace;
