import React from "react";

const SignUpProcess = () => {
  return (
    <>
      <div className="work-process-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">OUR SIMPLE SIGN UP PROCESS</h6>
            <h2 className="title">
              Simple <span>5 Steps</span> To Sign Up Your Account
            </h2>
          </div>

          <div className="row work-process-container">
            <div className="col-xl-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/9.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">Step 1</p>
                  <h5 className="mb-3">CreRadar Account</h5>
                  <p className="content">
                    Join our community by registering with your email and a
                    secure password, and unlock access to a world of
                    entrepreneurial resources and support.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/10.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">Step 2</p>
                  <h5 className="mb-3">Personal Information</h5>
                  <p className="content">
                    Help us get to know you better by sharing your essential
                    personal details, such as your background, education, and
                    interests, so we can better tailor our services to your
                    needs.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/11.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">Step 3</p>
                  <h5 className="mb-3">Your Role</h5>
                  <p className="content">
                    Define your unique place in the business world and identify
                    the specific areas of focus or industries that align with
                    your vision, so we can connect you with the right partners
                    and opportunities.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/12.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">Step 4</p>
                  <h5 className="mb-3">Looking For What Role?</h5>
                  <p className="content">
                    Explore the exciting possibilities of starting your own
                    business by determining the ideal role you would like to
                    play, and let us help you navigate the complexities of
                    launching your startup.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/3.png" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">Step 5</p>
                  <h5 className="mb-3">Your Skills and Experience</h5>
                  <p className="content">
                    Identify and showcase your valuable skills and relevant
                    experience to potential partners, investors, and customers,
                    and position yourself for success in the competitive startup
                    landscape.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpProcess;
