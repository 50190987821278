import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceBanner = () => {
  return (
    <>
    <div className="ser-match-banner-area">

      <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="assets/img/cofoundersgroupmeeting.png"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3
            style={{
                color: "white",
            }}
            >Right People, Right Fit!</h3>
            <p style={{
                color: "gray",
            }}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="assets/img/startupgroup.png"
            alt="Second slide"
          />

          <Carousel.Caption>
          <h3
            style={{
                color: "white",
            }}
            >Right People, Right Fit!</h3>
            <p style={{
                color: "gray",
            }}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="assets/img/startuppitching.png"
            alt="Third slide"
          />

          <Carousel.Caption>
          <h3
            style={{
                color: "white",
            }}
            >Right People, Right Fit!</h3>
            <p style={{
                color: "gray",
            }}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>

      {/* ================== Service Banner Start ==================*/}

      {/* <div className="banner-area bg-relative service-banner-area">
        <div className="container custom-container">
          <div className="bg-gray">
            <img
              className="img-right"
              src="assets/img/cofounder.jpeg"
              alt="img"
            />
            <div className="container">
              <div className="row">
                <div className="col-lg-7 align-self-center">
                  <div className="banner-inner">
                    <h6
                      className="subtitle "
                      data-aos="fade-right"
                      data-aos-delay="100"
                      data-aos-duration="1500"
                    >
                      DESIGNING FOR YOUR SUCCESS
                    </h6>

                    <h2
                      className="title"
                      data-aos="fade-right"
                      data-aos-delay="250"
                      data-aos-duration="1500"
                    >
                      Right <span>People</span>, <br />
                      Right Fit!
                    </h2>
                    <Link
                      className="mt-3 btn btn-border-base"
                      data-aos="fade-right"
                      data-aos-delay="400"
                      data-aos-duration="1500"
                      // to='/about'
                    >
                      Discover More <FaPlus />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* ================== Service Banner End ==================*/}
    </>
  );
};

export default ServiceBanner;
