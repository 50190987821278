import React from 'react'
import { Link } from "react-router-dom";

const CofounderMatchingBreadcrumb = ({ title }) => {
  return (
    <>
    <div
      className="user-signup-breadcrumb bg-cover banner-bg-img"
      style={{ backgroundImage: 'url("./assets/img/white-bg.jpeg")' }}
    >
      <div className="container">
        <div className="breadcrumb-inner">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h2 className="page-title">{title}</h2>
            </div>
            <div className="col-lg-6 text-lg-end">
              <ul className="page-list">
                <li>
                  <Link to="/service">Co-Founders Matching</Link>
                </li>
                {"  "}/ <li>{title}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "8px",
        }}
      >
        <h3
          style={{
            color: "gray",
            marginTop: "30px",
            marginBottom: "30px"
          }}
        >
          Congratulations! You have been paired with your co-founder match!
        </h3>
      </div>
    </div>
  </>
  )
}

export default CofounderMatchingBreadcrumb