import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { animated } from "react-spring";
import styled from "styled-components";

import CofounderMatchingBreadcrumb from "./CofounderMatchingBreadcrumb";

const MatchedCofounderArea = () => {
  return (
    <>
      <div className="blog-area  pd-top-90 pd-bottom-120">
        <div className="matched-founder matched-breadcrumb">
          <CofounderMatchingBreadcrumb title={"Matched Partner"} />
        </div>

        <div className="matched-profile-out-container">
          <div className="matched-container">
            <div className="matched-profile">
              <div className="founder-details">
                <div className="founder-img">
                  <img
                    src="assets/img/smart-matching/user-icon/user-daniel-wong.png"
                    alt="img"
                    style={{
                      borderRadius: "50%",
                      border: "2px solid gray",
                      height: "200px",
                      width: "200px",
                    }}
                  />
                </div>

                <div className="personal-details">
                  <h4 className="founder-name">Peter Chan</h4>
                  <h5 className="founder-industry" style={{ color: "gray" }}>
                    Innovation and Technology
                  </h5>
                  <div className="founder-social-media-area">
                    <ul className="social-media">
                      <li>
                        <a href="#">
                          <FaFacebookF />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaTwitter />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaInstagram />
                        </a>
                      </li>
                    </ul>
                  </div>

                  <p>
                    Peter Chan is an experienced software developer with a
                    strong background in Web3.0 platform development, NFT, and
                    smart contract programming. He has expertise in building
                    decentralized applications using blockchain technology and
                    has experience in creating non-fungible tokens. Peter is now
                    seeking a co-founder who is interested in starting an
                    innovative crypto project. With his extensive knowledge and
                    skills in the field, Peter is an excellent partner for
                    anyone looking to develop a groundbreaking blockchain-based
                    solution.
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="widget widget_catagory">
                      <h5 className="widget-title">Matching records</h5>
                      <ul className="catagory-items">
                        <li>
                          <Link to="/matched-cofounder">
                            Co-Founder <span>3</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/matched-cofounder">
                            Mentor / Advisor <span>7</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/matched-cofounder">
                            Team member <span>1</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/matched-cofounder">
                            Startup <span>0</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="widget widget_tag_cloud mb-0">
                      <h4 className="widget-title">Skills</h4>
                      <div className="tagcloud">
                        <a href="#">NFT</a>
                        <a href="#">Crypto</a>
                        <a href="#">Finance</a>
                        <a href="#">Operation</a>
                        <a href="#">Management</a>
                        <a href="#">Web 3.0</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="connect-btn">
                <ConnectButton>Connect</ConnectButton>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar">
                <div className="widget widget_author text-center">
                  <div className="thumb">
                    <img src="assets/img/about/7.png" alt="img" />
                  </div>
                  <div className="details">
                    <h5>Peter Chan</h5>
                    <ul className="social-media">
                      <li>
                        <a href="#">
                          <FaFacebookF />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaTwitter />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaInstagram />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="widget widget_catagory">
                  <h4 className="widget-title">Matching records</h4>
                  <ul className="catagory-items">
                    <li>
                      <Link to="/matched-cofounder">
                        Co-Founder <span>3</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/matched-cofounder">
                        Mentor / Advisor <span>7</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/matched-cofounder">
                        Team member <span>1</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/matched-cofounder">
                        Startup <span>0</span>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="widget widget_tag_cloud mb-0">
                  <h4 className="widget-title">Skills</h4>
                  <div className="tagcloud">
                    <a href="#">NFT</a>
                    <a href="#">Crypto</a>
                    <a href="#">Finance</a>
                    <a href="#">Operation</a>
                    <a href="#">Management</a>
                    <a href="#">Web 3.0</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner">
                  <div className="details">
                    <ul className="blog-meta">
                      <li>
                        <FaRegUser /> By Admin
                      </li>
                      <li>
                        <FaRegFolderOpen /> Category
                      </li>
                    </ul>
                    <p>
                      Cras varius. Donec vitae orci sed dolor rutrum auctor.
                      Fusce egestas elit eget lorem. Suspendisse nisl elit,
                      rhoncus eget elementum acondimentum eget, diam. Nam at
                      tortor in tellus interdum sagitliquam lobortis. Donec orci
                      lectus, aliquam ut, faucibus non, euismod id, nulla.
                      Curabitur blandit mollis lacus. Nam adipiscing. Vestibulum
                      eu odio. Vivamus laoreet.
                    </p>
                    <p>
                      Lorem available market standard dummy text available
                      market industry Lorem Ipsum simply dummy text of free
                      available market.There are many variations of passages of
                      Lorem Ipsum available, but the majority have suffered
                      alteration in some form,
                    </p>

                    <h4>You’re in Good Hands With Allstate</h4>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English
                    </p>
                    <h4>It includes a key benefit</h4>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English
                    </p>
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> Creating a Balanced and Nutritious
                      </li>
                      <li>
                        <FaCheckCircle /> iTechnology that helps grow companies
                      </li>
                      <li>
                        <FaCheckCircle /> Everything you need to succeed
                      </li>
                    </ul>
                    <p>
                      Lorem available market standard dummy text available
                      market industry Lorem Ipsum simply dummy text of free
                      available market.There are many variations of passages of
                      Lorem Ipsum available, but the majority have suffered
                      alteration in some form.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

const ConnectButton = styled(animated.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 130px;
  padding: 0 20px;
  margin: 0 10px;
  background-color: #50af9b;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
  font-size: 20px;
  color: white;

  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #e6e6e6;
  }
`;

export default MatchedCofounderArea;
