import { createAsyncThunk } from "@reduxjs/toolkit";

const loginThunk = createAsyncThunk("loginThunk", async (params, thunkApi) => {
  const res = await fetch(`${process.env.REACT_APP_API_HOST}/user/login`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-type": "application/json",
    },
  });
  const data = await res.json();

  if (res.ok) {
    const token = data.token;
    return thunkApi.fulfillWithValue(token);
  }
  return thunkApi.rejectWithValue("Login Fail");
});

const updateProfile = createAsyncThunk(
  "updateProfile",
  async (params, { fulfillWithValue, rejectWithValue }) => {
    try {
      console.log("params:", params);

      const res = await fetch(
        process.env.REACT_APP_API_HOST + "/user/profile",
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
            "Content-type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      const data = await res.json();
      if (res.ok) {
        const token = data.token;
        return fulfillWithValue(token);
      }
      return rejectWithValue(data.message);
    } catch {
      return rejectWithValue("fail(thunk get Post");
    }
  }
);

const fbThunk = createAsyncThunk("fbThunk", async (params, thunkApi) => {
  const fbToken = params;
  console.log(fbToken);
  const res = await fetch(process.env.REACT_APP_API_HOST + "user/login/fb", {
    method: "POST",
    body: JSON.stringify({ fbToken: fbToken }),
    headers: {
      "Content-type": "application/json",
    },
  });
  const data = await res.json();
  if (res.ok) {
    const token = data.token;
    console.log("token:", token);
    return thunkApi.fulfillWithValue(token);
  }
  return thunkApi.rejectWithValue("Facebook Login Failed");
});

const googleThunk = createAsyncThunk(
  "googleThunk",
  async (params, thunkApi) => {
    const googleToken = params
    console.log(googleToken);
    const res = await fetch(process.env.REACT_APP_API_HOST + "/user/login/google", {
        method: "POST",
        body: JSON.stringify({accessToken: googleToken}),
        headers:{
            "Content-type": "application/json"
        }
    })
    const data = await res.json()
    if(res.ok){
        const token = data.token
        return thunkApi.fulfillWithValue(token)
    }
    return thunkApi.rejectWithValue("Google Login Failed")
  }
);

export { loginThunk, updateProfile, fbThunk, googleThunk };
